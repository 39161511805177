<template>
  <transition name="fade" :css="true">
    <div
      class="popup"
      :class="{ new_year_popup: new_year_theme }"
      @click="closePopup"
      v-show="visible"
    >
      <slot name="popup-body"></slot>
    </div>
  </transition>
</template>
<script>
export default {
  name: "v-popup",
  props: {
    visible: {
      type: Boolean,
      default: () => false,
    },
    opened: {
      type: Boolean,
      default: () => false,
    },
    confirmed: {
      type: Boolean,
      default: () => false,
    },
    new_year_theme: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    closePopup(e) {
      if (e.target.classList.contains("popup")) {
        this.$emit("closePopup");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999999999;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
}
</style>

<style lang="scss">
.new_year_popup {
  div {
    position: relative;
    border-radius: 20px;
    overflow: auto;
  }
}
</style>
