export default {
  updated (el, binding, vnode) {
    const regex = /[^0-9]/g
    const bind_val = binding.value.trim()
    el.oninput = function (e) {
      // let val = e.target.value;
      // let length = e.target.value.length;
      // console.log(length);
      // if (length == 4) {
      //   e.target.value = `${bind_val[0]} ${bind_val.slice(1, length)}`;
      // } else if (length == 6 || length == 5) {
      //   e.target.value = `${bind_val[0]}${bind_val[2]} ${bind_val.slice(
      //     3,
      //     length
      //   )}`;
      // } else if (length == 7) {
      //   e.target.value = `${bind_val[0]}${bind_val[1]}${
      //     bind_val[3]
      //   } ${bind_val.slice(4, length)}`;
      // } else if (length == 0) {
      //   e.target.value = "";
      // }
      // e.target.value = e.target.value
      //   .replace(/[^0-9.]/g, "")
      //   .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
  }
}
