<template>
  <transition name="fade" :css="true">
    <div class="v-notification" v-show="GLOBAL_NOTIFICATIONS?.items.length">
      {{ GLOBAL_NOTIFICATIONS?.title || "" }}
      {{ GLOBAL_NOTIFICATIONS?.items?.[0] }}
    </div>
  </transition>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "v-notification",
  computed: {
    ...mapGetters(["GLOBAL_NOTIFICATIONS"]),
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
.v-notification {
  width: 250px;
  min-height: 60px;
  padding: 20px;
  border-radius: 10px;
  font-size: 14px;
  font-family: "Lato", sans-serif;
  position: fixed;
  z-index: 999999999999999;
  top: 50px;
  right: 50px;
  background-color: $accent;
  color: $white;
}
</style>
