<template>
  <div class="v-preloader" ref="v_preloader">
    <!-- <div class="v-preloader__cirle circle first-circle"></div>
    <div class="v-preloader__cirle circle second-circle"></div>
    <div class="v-preloader__cirle circle third-circle"></div> -->
    <div class="spinner"></div>
  </div>
</template>
<script>
// import gsap from 'gsap'
export default {
  name: 'v-preloader',
  mounted () {}
}
</script>
<style lang="scss">
.v-preloader {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  // .v-preloader__cirle

  &__cirle {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 5px solid #000;
    border-top-color: #000;
    &::after {
      content: "";
      background-color: rgb(109, 63, 63);
      display: flex;
      top: 0;
      right: -5px;
      position: absolute;
      width: 55%;
      height: 100%;
    }
  }
}
.first-circle {
  width: 250px;
  height: 250px;
  z-index: 1;
}
.second-circle {
  width: 200px;
  height: 200px;
  z-index: 2;
}
.third-circle {
  width: 150px;
  height: 150px;
  z-index: 3;
}
</style>
<style lang="scss">
.spinner {
  width: 100px;
  height: 100px;
  display: grid;
  border: 4px solid #0000;
  border-right-color: #00649c;
  border-radius: 50%;
  animation: spinner-a4dj62 1.3s infinite linear;
}

.spinner::before,
.spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2.2px;
  border: inherit;
  border-radius: 50%;
  animation: spinner-a4dj62 2.5s infinite;
}

.spinner::after {
  margin: 8.9px;
  // animation-duration: 2.3s;
  animation-delay: 0.3s;
}

.spinner::before {
  border-right-color: #681eb2;
}
.spinner::after {
  border-right-color: #000;
}

@keyframes spinner-a4dj62 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
