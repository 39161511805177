import apiRequest from "../../../middlewares/api-request";
import { getCookie, setCookie } from "../../../helpers/helpers";

export default {
  async GET_USER() {
    return await apiRequest.function(
      "POST",
      "/lk/general/get_user",
      ["SET_USER"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_EMPLOYERS() {
    return await apiRequest.function(
      "GET",
      "/info/employers/get",
      ["SET_EMPLOYERS"],
      undefined,
      undefined
    );
  },
  async GET_EMPLOYER_TARIFFS({}, data) {
    return await apiRequest.function(
      "GET",
      "/info/employers/change",
      ["SET_EMPLOYER_TARIFFS"],
      data,
      undefined
    );
  },
  async CALCULATE_ORDER({}, data) {
    return await apiRequest.function(
      "GET",
      "/info/calculator",
      [],
      data,
      undefined
    );
  },
  async GET_REBID_DOCUMENTS() {
    return await apiRequest.function(
      "POST",
      "/lk/loan/repeat/lastStep",
      ["SET_REBID_DOCUMENTS"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_STAGE() {
    return await apiRequest.function(
      "POST",
      "/lk/general/get_stage",
      [],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_REABILITY_STATUS() {
    return await apiRequest.function(
      "POST",
      "/lk/general/get_reability_status",
      ["SET_REABILITY_STATUS"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
};
