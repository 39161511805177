const getCookie = function (name) {
  const matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};
const setCookie = function (name, value, maxAgeSeconds) {
  const maxAgeSegment = "; max-age=" + maxAgeSeconds;
  const secure = "; secure";
  const sameSite = "; SameSite=None";
  document.cookie =
    encodeURI(name) +
    "=" +
    encodeURI(value) +
    maxAgeSegment +
    secure +
    sameSite;
};

export { getCookie, setCookie };
