export default {
  profile: {},
  loans: {},
  curr_loan: {},
  bids: [],
  bills: [],
  cards: [],
  active_loans: [],
  lk_documents: [],
  back: false,
};
