export default {
  mounted (el, binding, vnode) {
    el.oninput = function (e) {
      if (e.target.value.length == +binding.arg) {
        if (
          el.nextElementSibling.childNodes[3].childNodes[1].childNodes[0]
            .tagName == 'INPUT'
        ) {
        // console.log(el);
        // console.log(binding);
        // console.log(vnode);
          el.blur()
          el.nextElementSibling?.childNodes[3].childNodes[1].childNodes[0].focus()
        }
      }
    }
  }
}
