import vHeader from '@/components/UI/v-header'
import vFooter from '@/components/UI/v-footer'
import vInput from '@/components/UI/v-input'
import vRadio from '@/components/UI/v-radio'
import vOtpInput from '@/components/UI/v-otp-input'
import vRequisitesRadio from '@/components/UI/v-requisites-radio'
import vBackBtn from '@/components/UI/v-back-btn'
import vTariffList from '@/components/tariffs/v-tariff-list'
import vBillList from '@/components/bills/v-bill-list'
import vChip from '@/components/UI/v-chip'
import vIcon from '@/components/UI/v-icon'
import vDivider from '@/components/UI/v-divider'
import vBtn from '@/components/UI/v-btn'
import vCheckbox from '@/components/UI/v-checkbox'
import vTooltip from '@/components/UI/v-tooltip'
import vPopup from '@/components/UI/v-popup'
import vSelect from '@/components/UI/v-select'
import vPreloader from '@/components/UI/v-preloader'

export default [
  vHeader,
  vFooter,
  vInput,
  vRadio,
  vOtpInput,
  vRequisitesRadio,
  vBackBtn,
  vTariffList,
  vIcon,
  vDivider,
  vChip,
  vBtn,
  vCheckbox,
  vTooltip,
  vPopup,
  vSelect,
  vBillList,
  vPreloader
]
