<template>
  <button class="v-btn">
    <span class="v-btn__name">
      <slot name="btn-name"></slot>
    </span>
  </button>
</template>
<script>
export default {
  name: 'v-btn'
}
</script>
<style lang="scss">
.v-btn {
  @include cta-btn;
  // .v-btn__name

  &__name {
  }
}
</style>
