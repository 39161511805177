<template>
  <header class="header">
    <div class="header__container">
      <nav class="header__nav">
        <router-link class="header__link header__logo" to="/">
          <img
            src="@/assets/img/logo.png"
            alt="Логотип"
            class="header__logo-image"
          />
          <span class="header__logo-text">
            <strong>Рестарт</strong> Онлайн</span
          >
        </router-link>
      </nav>
      <slot> </slot>
    </div>
  </header>
</template>
<script>
export default {
  name: "v-header",
};
</script>
<style lang="scss">
.header {
  padding: 0;
  z-index: 1;

  // .header .standart

  .standart {
    padding: 5px 0 25px 0;
  }
  // .header__container

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include container;
  }

  // .header__wrapper

  &__wrapper {
    display: flex;
    a {
      margin-left: 20px;
    }
  }

  // .header__nav

  &__nav {
  }

  // .header__link

  &__link {
  }

  // .header__btn

  &__btn {
    @include cta-btn;
  }

  // .header__logo

  &__logo {
    display: flex;
    align-items: center;
    position: relative;
  }

  // .header__logo-image

  &__logo-image {
    margin-left: -41px;
  }

  // .header__logo-text

  &__logo-text {
    // margin-left: 80%;
    margin-left: -31px;
    margin-bottom: 30px;
    font-size: 20px;
    font-family: "AkzidenzGroteskPro-Ext", sans-serif;
    strong {
      font-weight: 900;
      font-family: "AkzidenzGroteskPro-BoldEx", sans-serif;
      color: $accent;
    }
  }

  // .header__wrapper

  &__wrapper {
  }
}

@media (max-width: 1600px) {
  .header {
    &__container {
      padding: 0 15px;
    }
    &__logo {
      padding-left: 25px;
    }
  }
}

@media (max-width: 768px) {
  .header {
    // .header__container

    &__container {
      flex-direction: column;
    }

    // .header__logo

    &__logo {
      padding-left: 0;
    }
  }
}

@media (max-width: 575px) {
  .header {
    // .header__wrapper

    &__wrapper {
      flex-direction: column;
      align-items: center;
      justify-content: center;

      a {
        margin-left: 0;
      }
    }
    // .header__btn

    &__btn {
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }
}
</style>
