import axios from "axios";
import store from "../store/index";
import router from "../router/index";

export default {
  async function(method, url, mutations, params, payload, headers = {}) {
    return await axios({
      method,
      params,
      headers,
      data: payload,
      url: process.env.VUE_APP_API_URL + url,
    })
      .then((res) => {
        // console.log(res);
        // console.log(url);
        // console.log(
        //   router.currentRoute.value.path == "/registration" && res.status == 404
        // );

        if (mutations.length) {
          console.log(mutations);
          for (const func of mutations) {
            store.commit(func, res.data);
          }
        }
        return res;
      })
      .catch((error) => {
        if (error?.response?.status == 401) {
          store.dispatch("LOGOUT");
          store.state.global_notifications.notification =
            "Токен истёк или не валиден, пожалуйста пройдите авторизацию";
          store.commit("SET_GLOBAL_NOTIFICATION");
          return;
        }
        return error.response;
      });
  },
};
