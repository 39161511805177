const getDateDiff = function (startDate, endDate) {
  const diff = (new Date(startDate) - new Date(endDate)) / 1000;
  //   console.log(rnd(diff / 86400));
  //   console.log(rnd(diff / 3600));
  //   console.log(rnd(diff % 60));
  return +rnd(diff / 60);
};

const rnd = function (n, p = 2) {
  return String(Math.floor(n)).padStart(p, "0");
};

export { getDateDiff };
