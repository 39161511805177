import { createStore, Store } from "vuex";
import axios from "axios";
import router from "@/router";
// import FormData from 'form-data'
import { toDate, dateReverse, toServerPhone } from "../helpers/converters.js";
import { getDateDiff } from "../helpers/compares.js";
import { getCookie } from "../helpers/helpers";
import { registration_form } from "../helpers/data/registration_data.js";
import rebidActions from "./modules/rebid/actions";
import rebidMutations from "./modules/rebid/mutations";
import rebidState from "./modules/rebid/state";
import rebidGetters from "./modules/rebid/getters";
import helpersActions from "./modules/helpers/actions";
import signinActions from "./modules/signin/actions";
import signinMutations from "./modules/signin/mutations";
import signinState from "./modules/signin/state";
import signinGetters from "./modules/signin/getters";
import globalActions from "./modules/global/actions";
import globalMutations from "./modules/global/mutations";
import globalState from "./modules/global/state";
import globalGetters from "./modules/global/getters";
import signupActions from "./modules/signup/actions";
import signupMutations from "./modules/signup/mutations";
import signupState from "./modules/signup/state";
import signupGetters from "./modules/signup/getters";
import apiRequest from "../middlewares/api-request";

const FormData = require("form-data");

function setGlobalNotification(state, notif) {
  console.log(state.global_notifications);
}

export default createStore({
  state: {
    apiURL: process.env.VUE_APP_API_URL,
    ...globalState,
    ...rebidState,
    ...signinState,
    ...signupState,
    registration_id: undefined,
    rebid: false,
    registration_layout_settings: {},
    global_notifications: {
      title: "",
      items: [],
      notification: "",
    },
    general_info: {},
    photos_complusion: false,
    test: {},
    balance: 0,
    token: "",
    refresh_token: "",
    connection_contacts: [],
    auth_data: {
      login: "",
      password: "",
    },
    authorized: false,
    auth_code: undefined,
    requisites: {
      cards: [],
      accounts: [],
    },
    documents: {},
    photos: [],
    auth_form: {
      login: {
        errorBag: [],
      },
      password: {
        errorBag: [],
      },
    },
    registration_form: {
      creditionals: {},
      zero_step: {},
      first_step: {},
      second_step: {},
      third_step: {},
      fourth_step: {},
      fifth_step: {},
      sixth_step: {},
    },
    tariffs: [],
    document_04031: "",
    bill_template: {},
    card_template: {},
    cards: [],
    fourth_step: {
      documents: {
        first_doc: "",
        second_doc: "",
        third_doc: "",
        fourth_doc: "",
        fifth_doc: "",
        sixth_doc: "",
      },
    },
  },
  getters: {
    ...globalGetters,
    ...signinGetters,
    ...rebidGetters,
    ...signupGetters,
    REGISTRATION_ID(state) {
      return state.registration_id;
    },
    REBID_FLAG(state) {
      return state.rebid;
    },
    REGISTRATION_LAYOUT(state) {
      return state.registration_layout_settings;
    },
    GLOBAL_NOTIFICATIONS(state) {
      return state.global_notifications;
    },
    GENERAL_INFO(state) {
      return state.general_info;
    },
    TOKEN(state) {
      return state.token;
    },
    AUTH_DATA(state) {
      return state.auth_data;
    },
    AUTH_CODE(state) {
      return state.auth_code;
    },
    AUTHORIZED(state) {
      return state.authorized;
    },
    AUTH_FORM_DATA(state) {
      return state.auth_form;
    },
    TARIFFS(state) {
      return state.tariffs;
    },
    REGISTRATION_FORM(state) {
      return state.registration_form;
    },
    REQUISITES(state) {
      return state.requisites;
    },
    FOURTH_STEP_DOCUMENTS(state) {
      return state.fourth_step.documents;
    },
    NOTIFICATIONS(state) {
      return state.connection_contacts;
    },
    REFRESH_AUTH_TOKEN(state) {
      return state.refresh_token;
    },
    // END_DOCUMENTS(state) {
    //   return state.end_documents;
    // },
  },
  mutations: {
    ...globalMutations,
    ...rebidMutations,
    ...signinMutations,
    ...signupMutations,
    SET_REGISTRATION_ID: (state, res) => {
      state.registration_id = res;
    },
    CHECK_IS_AUTH: (state, res) => {
      if (state?.token?.length <= 10) return;
      else {
        state.token = "";
        localStorage.removeItem("isAuth");
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        // router.push("/auth");
      }
    },
    SET_AUTH_TOKENS: (state, { access_token, refresh_token }) => {
      if (access_token !== undefined) {
        state.token = access_token;
        state.refresh_token = refresh_token;
        localStorage.setItem("token", access_token);
        localStorage.setItem("refresh_token", refresh_token);
      }
    },
    CLEAN_CHACHE: (state, keys) => {
      for (const item of keys) {
        if (item == "saved_form") {
          // localStorage.setItem("saved_form", JSON.stringify(registration_form));
        }
        if (item == "savedDate") {
          localStorage.removeItem("savedDate");
        }
      }
    },
    CHECK_CHACHE: (state, { start_date, end_date }) => {
      console.log(start_date, end_date);
    },
    SET_REGISTRRATION_LAYOUT: (state, data) => {
      state.registration_layout_settings = data;
    },
    SET_TARIFF_FROM_HOME: (state, data) => {
      state.tariffs.map((item) => (item.selected = false));
      if (state.tariffs.filter((item) => +item.id === +data.id).length) {
        state.tariffs.filter(
          (item) => +item.id === +data.id
        )[0].selected = true;
        return;
      }
    },
    SET_GLOBAL_NOTIFICATION: (state, data) => {
      if (data == "") {
        return;
      }
      if (data == undefined) {
        state.global_notifications.items.push(
          state.global_notifications.notification
        );

        state.global_notifications.notification = "";

        setTimeout(() => {
          state.global_notifications.items.splice(0, 1);
        }, 5000);
      } else {
        state.global_notifications.items.push(
          state.global_notifications.notification
        );

        state.global_notifications.notification = "";

        setTimeout(() => {
          state.global_notifications.items.splice(0, 1);
        }, 5000);
      }
    },
    SET_GENERAL_INFO: (state, data) => {
      state.general_info = data;
    },
    SET_TEST_TO_STATE: (state, data) => {
      state.test = data;
    },
    SET_AUTH: (state, res) => {
      // res.data.stage_registration = res.data.stage_registration - 1;
      console.log(res);
      if (res.success) {
        state.auth_form = {
          login: {
            errorBag: [],
          },
          password: {
            errorBag: [],
          },
        };

        state.token = res.data.access_token;
        state.refresh_token = res.data.refresh_token;
        state.authorized = true;
        localStorage.setItem("isAuth", true);
        router.push("/profile");
      } else if (res.success == false) {
        switch (res.error.description) {
          case "Пользователь не найден":
            state.auth_form.login.errorBag.push({
              name: res.error.description,
            });
            break;
          case "Пароль не совпадает":
            state.auth_form.password.errorBag.push({
              name: res.error.description,
            });
            break;
          default:
            break;
        }
      }
    },
    SET_AUTH_DATA: (state, { login, password }) => {
      state.auth_data.login = login;
      state.auth_data.password = password;
    },
    SET_AUTH_MESSENGER: (state, code) => {
      state.auth_code = code;
    },
    SET_DOCUMENTS: (state, res) => {
      const data = res.data.data;
      const filtered = new Set(
        data.map((item) => {
          return (item.order_id = item.order_id.toString());
        })
      );
      const filtered2 = [];
      for (const el of filtered) {
        filtered2.push(new Array(data.filter((item) => item.order_id == el)));
      }
      state.documents = filtered2;
    },
    SET_TARIFFS: (state, res) => {
      const colors = ["#00015D", "#00649C", "#681EB2"];
      const stock = "#ED2F41";
      const abjArr = Object.entries(res);
      if (typeof res == "object") {
        abjArr.forEach(([key, value]) => {
          if (state.tariffs.filter((item) => item.id == value.id).length) {
          } else {
            state.tariffs.push(value);
          }
        });
      } else {
        state.tariffs = res;
      }

      // state.tariffs = [res.data.data[0], res.data.data[1]]
      const saved_form = JSON.parse(localStorage.getItem("saved_form"));
      for (
        let idx = 0, colorsIdx = 0;
        idx < state.tariffs.length;
        idx++, colorsIdx++
      ) {
        let colorLimit = 3;
        if (colorsIdx >= colorLimit) {
          colorsIdx = 0;
        }
        state.tariffs[idx].head_color = colors[colorsIdx];
      }
      state.tariffs
        .filter((item) => +item?.number <= 20)
        ?.map((item) => (item.head_color = stock));
      if (
        localStorage.getItem("home_tariff") !== "undefined" &&
        localStorage.getItem("home_tariff") !== "null" &&
        localStorage.getItem("home_tariff") !== null &&
        localStorage.getItem("home_tariff") !== undefined
      ) {
        const home_tariff = JSON.parse(localStorage.getItem("home_tariff"));
        if (
          !Array.from(state.tariffs).filter(
            (item) => +item.id === +home_tariff.id
          ).length
        ) {
          maxTariff.selected = true;
          state.global_notifications.notification =
            "У данного работодателя, выбранный тариф отсутствует, ознакомьтесь с актуальными тарифами";
          localStorage.setItem("home_tariff", JSON.stringify(null));
          return;
        }
        if (
          state.tariffs.filter((item) => +item.id === +home_tariff.id).length
        ) {
          state.tariffs.filter(
            (item) => +item.id === +home_tariff.id
          )[0].selected = true;
          localStorage.setItem("home_tariff", JSON.stringify(null));
          return;
        }
        return;
      }

      if (
        saved_form == null ||
        saved_form?.creditionals?.tariff_id?.value == "" ||
        saved_form == undefined
      ) {
        state.tariffs[0].selected = true;
        return;
      }

      for (const item of state.tariffs) {
        item.id = item.id + "";
        if (saved_form !== null) {
          if (+item.id == +saved_form?.creditionals?.tariff_id?.value) {
            item.selected = true;
            return;
          }
        }
      }

      // state.tariffs.map((item) => state.tariffs.push(item));
    },
    MUTATE_ZERO_STEP: (state, res) => {
      state.registration_form.zero_step = res.zero_step;
      state.registration_form.creditionals = res.creditionals;
      state.registration_form.zero_step.phone.value =
        state.registration_form.zero_step.phone.value.replace(/[^\d.]/gi, "");
      state.registration_form.zero_step.date_of_birth.value = toDate(
        state.registration_form.zero_step.date_of_birth.value
      );
      state.registration_form.creditionals.return_to.value =
        state.registration_form.creditionals.return_to.value.replace(
          /[-]/gi,
          "."
        );
    },
    SET_NEW_TOKENS: (state, { data, success }) => {
      if (success) {
        state.refresh_token = data.refresh_token;
        state.token = data.access_token;
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("refresh_token", data.refresh_token);
        localStorage.setItem("isAuth", true);
      } else {
        localStorage.setItem("isAuth", false);
      }
    },
    SET_TOKENS: (state, res) => {
      // if (state.token !== undefined || state.token?.length > 10) return
      const token = localStorage.getItem("token");
      const refresh_token = localStorage.getItem("refresh_token");
      if (token !== "" || token !== undefined) {
        state.token = token;
      } else {
        state.token = "";
      }
      if (refresh_token !== "" || refresh_token !== undefined) {
        state.refresh_token = refresh_token;
      } else {
        state.refresh_token = "";
      }
    },
    MUTATE_FIRST_STEP: (state, res) => {
      state.registration_form.first_step = res.first_step;
      state.registration_form.first_step.issued.value = toDate(
        state.registration_form.first_step.issued.value
      );
      state.registration_form.first_step.fio_change_date.value = toDate(
        state.registration_form.first_step.fio_change_date.value
      );
      state.registration_form.first_step.registration_address_match.value =
        state.registration_form.first_step.registration_address_match.value
          .length
          ? state.registration_form.first_step.registration_address_match
              .value[0]
          : "0";
    },
    MUTATE_SIXTH_STEP: (state, res) => {
      state.registration_form.sixth_step = res.sixth_step;
      // state.registration_form.sixth_step.value = ''
    },
    MUTATE_SECOND_STEP: (state, res) => {
      state.registration_form.second_step = res.second_step;
      state.registration_form.second_step.whatsapp.value =
        state.registration_form.second_step.whatsapp.value.replace(
          /[^\d.]/gi,
          ""
        );
      state.registration_form.second_step.viber.value =
        state.registration_form.second_step.viber.value.replace(/[^\d.]/gi, "");
    },

    MUTATE_THIRD_STEP: (state, res) => {
      state.registration_form.third_step = res.third_step;
    },
    MUTATE_FIFTH_STEP: (state, res) => {
      state.registration_form.fifth_step = res.fifth_step;
      if (
        state.registration_form.fifth_step.prof_union_notification.value.length
      ) {
        state.registration_form.fifth_step.prof_union_notification.value = "1";
      } else {
        state.registration_form.fifth_step.prof_union_notification.value = "0";
      }
    },
    MUTATE_BILL_FIELDS: (state, res) => {
      state.bill_template = res;
    },
    SET_CARDS: (state, res) => {
      state.cards = res.data;
    },
    MUTATE_CARD_FIELDS: (state, res) => {
      state.card_template = {
        pan: res.number.replace(/\s/g, ""),
        expdate: res.date,
        default: "0",
      };
    },
    // SET_FOURTH_STEP_DOCUMENTS: (state, res) => {
    //   const filtered = [];

    //   for (const item of res.data.data) {
    //     if (item.name == "Согласие на обработку персональных данных") {
    //       state.fourth_step.documents.sixth_doc = item.link;
    //     }
    //     if (
    //       item.name ==
    //       "Согласие на обработку персональных данных РуКредом и распространение работодателю"
    //     ) {
    //       state.fourth_step.documents.first_doc = item.link;
    //     }
    //     if (
    //       item.name ==
    //       "Согласие работодателю на распространение персональных данных"
    //     ) {
    //       state.fourth_step.documents.second_doc = item.link;
    //     }
    //     if (
    //       item.name ==
    //       "Согласие на обработку персональных данных и упрощенную идентификацию через МИнБ"
    //     ) {
    //       state.fourth_step.documents.third_doc = item.link;
    //     }
    //     if (
    //       item.name ==
    //       "Согласие на обработку персональных данных и упрощенную идентификацию через РДБ"
    //     ) {
    //       state.fourth_step.documents.fourth_doc = item.link;
    //     }

    //     if (item.name == "Согласие заемщика на получение кредитного отчета") {
    //       state.fourth_step.documents.fifth_doc = item.link;
    //     }

    //     if (
    //       item.name ==
    //       "Обязательство о подаче заявления в адрес работодателя на перечисление части зп"
    //     ) {
    //       state.fourth_step.documents.seventh_doc = item.link;
    //     }
    //   }
    // },
    SET_CONNECTION_CONTACTS: (state, res) => {
      state.connection_contacts = res.data;
      state.connection_contacts.map((item) => {
        // if (item.name == "SMS-уведомления") item.disabled = true;
        // else item.disabled = false;
        item.disabled = true;
      });
    },
    SET_EMPLOYER_TARIFFS_FOR_BIDS: (state, res) => {
      if (typeof res.data.data == "string") {
        state.global_notifications.notification = "Нет подходящих тарифов";
        state.tariffs = [];
        return;
      }
      state.tariffs = res.data.data;
      const colors = ["#00015D", "#00649C", "#681EB2"];
      const stock = "transparent";

      if (
        !state.tariffs.filter((item) => +item.id === +state.rebid_tariff).length
      ) {
        state.global_notifications.notification =
          "У данного работодателя, выбранный тариф отсутствует, ознакомьтесь с актуальными тарифами";
        state.tariffs[0].selected = true;
      }

      let maxTariffAmount = Math.max(
          ...state.tariffs.map((item) => item?.max_amount)
        ),
        maxTariff = state.tariffs.filter(
          (item) => item?.max_amount == maxTariffAmount
        )?.[0],
        nextTariffIdx = state.tariffs
          .map((item) => +item.id)
          .indexOf(+state.rebid_tariff),
        nextTariff = state?.tariffs[nextTariffIdx];

      if (state.rebid_tariff == undefined) {
        // let filtred = state.tariffs
        // maxTariff.selected = true;
        maxTariff.selected = true;
      }

      // if (state.rebid_tariff !== undefined) {
      //   let currTariff = Array.from(state.tariffs).filter(
      //     (item) => +item.id === +state.rebid_tariff
      //   )?.[0];
      //   currTariff.selected = true;
      // }

      for (
        let idx = 0, colorsIdx = 0;
        idx < state.tariffs.length;
        idx++, colorsIdx++
      ) {
        let colorLimit = 3;
        if (colorsIdx >= colorLimit) {
          colorsIdx = 0;
        }
        state.tariffs[idx].head_color = colors[colorsIdx];
      }
      state.tariffs
        .filter((item) => +item?.number <= 20)
        ?.map((item) => (item.head_color = stock));
      if (
        state.tariffs.filter((item) => +item.id === +state.rebid_tariff).length
      ) {
        state.tariffs.filter(
          (item) => +item.id === +state.rebid_tariff
        )[0].selected = true;
        return;
      }

      // for (const item of state.tariffs) {
      //   item.id = item.id + "";
      //   if (nextTariff == undefined) {
      //     maxTariff.selected = true;
      //     state.global_notifications.notification =
      //       "У данного работодателя, выбранный тариф отсутствует, ознакомьтесь с актуальными тарифами";
      //     return;
      //   } else {
      //     // state.global_notifications.notification;
      //     // ("у данного работодателя, выбранный тариф отсутствует, ознакомьтесь с актуальными тарифами");
      //     nextTariff.selected = true;
      //     return;
      //   }
      // }
    },
    SET_REBID: (state, bool) => {
      state.rebid = bool;
    },
  },
  actions: {
    ...globalActions,
    ...helpersActions,
    ...signinActions,
    ...rebidActions,
    ...signupActions,
    GET_REGISTRATION_ID({ commit }, data) {
      console.log("orderId регистрации", data);
      commit("SET_REGISTRATION_ID", data);
    },
    REBID({ commit }, bool) {
      commit("SET_REBID", bool);
    },
    CHANGE_TOKEN({ commit }, data) {
      commit("SET_AUTH_TOKENS", data);
    },
    GLOBAL_CHACHE({ commit }, data) {
      const savedDate = JSON?.parse(localStorage?.getItem("savedDate"));
      const date = new Date()
        ?.toLocaleString()
        ?.replace(/[.]/g, "-")
        ?.replace(/[,]/g, "");
      for (const item of data?.actions) {
        if (item == "setSavedDate") {
          localStorage.setItem(
            "savedDate",
            JSON.stringify(
              new Date()
                ?.toLocaleString()
                ?.replace(/[.]/g, "-")
                ?.replace(/[,]/g, "")
            )
          );
          return "saveLastChangeDate";
        }
        if (item == "getDateDiff") {
          let dateDiff = getDateDiff(dateReverse(date), dateReverse(savedDate));
          console.log(`разница в минутах ${dateDiff}`);
          if (dateDiff >= 59) {
            commit("CLEAN_CHACHE", ["saved_form", "savedDate"]);

            return "refreshForm";
          } else {
            commit("CLEAN_CHACHE", ["savedDate"]);
            return "refreshLastChangeDate";
          }
        }
      }
    },
    CHANGE_REGISTRATION_LAYOUT({ commit }, settings) {
      commit("SET_REGISTRRATION_LAYOUT", settings);
    },
    CHANGE_TARIFF_FROM_HOME({ commit }, data) {
      commit("SET_TARIFF_FROM_HOME", data);
    },
    async GET_GLOBAL_NOTIFICATION({ commit }, data) {
      commit("SET_GLOBAL_NOTIFICATION", data);
    },
    async GET_GENERAL_INFO({ commit, state }, { phone, action }) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token}`);
      const formdata = new FormData();
      if (action !== "getUser") {
        formdata.append(
          "phone",
          phone
            ?.replace(/[+()-]/g, "")
            ?.split(" ")
            ?.join("")
        );
      }
      formdata.append("action", action);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      return await fetch(
        `${process.env.VUE_APP_API_URL}/info/general`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (action == "getUser") {
            commit("SET_GENERAL_INFO", res.data);
            return res.data;
          }
          if (action == "getStage") {
            commit("SET_REGISTRATION_ID", res.data?.orderId);
            return res.data;
          }
          if (action == "getDefaultSettlement") {
            return res.data;
          }
          return res;
        });
    },
    async GET_PHOTOS_COMPLUSION({ commit, state }) {
      if (!state.photos.length) {
        // await this.dispatch("GET_PHOTOS");
      }
      if (state.photos.filter((item) => item.status == 3).length) {
        return true;
      } else {
        return false;
      }
    },
    // async BIND_MESSENGER({ commit, state }, { val, messenger }) {
    //   let payload = {};
    //   if (messenger == "telegram") {
    //     payload.phone = val;
    //   }
    //   if (messenger == "viber") {
    //     payload.email = val.toLowerCase();
    //   }
    //   return await apiRequest.function(
    //     "POST",
    //     `/messengers/main/${messenger}`,
    //     [],
    //     undefined,
    //     payload,
    //     { Authorization: `${getCookie("token")}` }
    //   );
    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${state.token}`);
    //   const formdata = new FormData();
    //   if (messenger == "telegram") {
    //     formdata.append("phone", val);
    //   }
    //   if (messenger == "viber") {
    //     formdata.append("email", val.toLowerCase());
    //   }
    //   // formdata.append("user_id", "4844");
    //   formdata.append("user_id", state?.general_info?.id);

    //   const requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: formdata,
    //     redirect: "follow",
    //   };

    //   return await fetch(
    //     `${process.env.VUE_APP_API_URL}/messengers/${messenger}`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((res) => {
    //       console.log(res);
    //       return res;
    //     });
    // },
    // async CHANGE_ACTIVE_REQUISITE({ commit, state }, { id, user_id }) {
    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${state.token}`);

    //   const formdata = new FormData();
    //   formdata.append("requisite_id", id);
    //   formdata.append("user_id", user_id);

    //   const requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: formdata,
    //     redirect: "follow",
    //   };

    //   return await fetch(
    //     `${process.env.VUE_APP_API_URL}/account/requesits/change`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((res) => {
    //       console.log(res);
    //       return res;
    //     });
    // },
    async UPLOAD_ACCOUNT_PHOTO({ commit, state }, data) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `${getCookie("token")}`);

      const formdata = new FormData();
      formdata.append("file", data.file);
      formdata.append("type", data.type);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
        headers: myHeaders,
      };

      return await fetch(
        `${process.env.VUE_APP_API_URL}/lk/photos/add`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    },
    // async GET_END_DOCUMENTS({ commit, state }, data) {
    //   return await axios({
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/x-www-form-urlencoded",
    //       Authorization: `Bearer ${state.token}`,
    //     },
    //     url: `${process.env.VUE_APP_API_URL}/document/registration/end`,
    //   })
    //     .then((res) => {
    //       const payload = {
    //         documents: res.data.data,
    //         data: data,
    //       };
    //       commit("SET_END_DOCUMENTS", payload);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    async REPLENISH_BALANCE({ commit, state }) {
      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `${process.env.VUE_APP_API_URL}/account/link/balance`,
      })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response.data;
        });
    },
    async PARTIALLY_REPAY({ commit, state }, data) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token}`);

      const formdata = new FormData();

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      return await fetch(
        `${process.env.VUE_APP_API_URL}/payment/part`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (!res.success) {
            return res;
          }
        })
        .catch((error) => console.log("error", error));
    },
    async FULL_REPAY({ commit, state }, data) {
      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token}`);

      const formdata = new FormData();

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: formdata,
        redirect: "follow",
      };

      return await fetch(
        `${process.env.VUE_APP_API_URL}/payment/full`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    async CHECK_AUTH_EMAIL_CODE({ commit, state }, data) {
      const formdata = new FormData();
      formdata.append("login", data.login);
      formdata.append("code", data.code);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      return await fetch(
        `${process.env.VUE_APP_API_URL}/auth/check/code`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          if (res.success) {
            commit("SET_NEW_TOKENS", res);
            console.log("51651");
            // router.push("/profile");
            return res;
          }
          return res;
        })
        .catch((error) => console.log("error", error));
    },
    async SEND_AUTH_EMAIL_CODE({ commit, state }, data) {
      const formdata = new FormData();
      formdata.append("login", data);
      formdata.append("type", "email");

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      return await fetch(
        `${process.env.VUE_APP_API_URL}/auth/send/code`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          return res;
        });
    },
    async GET_BANALCE({ commit, state }) {
      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `${process.env.VUE_APP_API_URL}/account/balance`,
      }).then((res) => {
        if (res.data.success) commit("SET_USER_BALANCE", res.data.data);
      });
    },
    async GET_CONNECTION_CONTACTS({ commit, state }) {
      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${process.env.VUE_APP_API_URL}/info/contact`,
      }).then((res) => {
        if (res.data.success) commit("SET_CONNECTION_CONTACTS", res.data);
      });
    },
    async SEND_VERIFICATION_EMAIL_CODE({ commit, state }, data) {
      const formdata = new FormData();
      formdata.append("login", data.login);
      formdata.append("type", data.type);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${process.env.VUE_APP_API_URL}/verification/email/send`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    async SEND_VERIFICATION_PHONE_CODE({ commit, state }, data) {
      // if (
      //   !state?.state?.general_info?.general?.id ||
      //   state?.state?.general_info?.general?.id == "Такого пользователя нет"
      // ) {
      //   return;
      // }
      const formdata = new FormData();
      formdata.append("phone", data.login.replace(/[+() -]/gi, ""));
      formdata.append("type", data.type);
      formdata.append("userId", state?.general_info?.id);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      return await fetch(
        `${process.env.VUE_APP_API_URL}/verification/phone/send`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          return res;
        });
    },
    async SEND_EMAIL_CODE({ commit, state }, data) {
      const formdata = new FormData();
      formdata.append("email", data.login);
      formdata.append("type", data.type);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(
        `${process.env.VUE_APP_API_URL}/verification/email/send`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    },
    async GET_DOCUMENTS_FOURTH_STEP({ commit, state }) {
      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `${process.env.VUE_APP_API_URL}/document/registration`,
      })
        .then((res) => {
          commit("SET_FOURTH_STEP_DOCUMENTS", res);
        })
        .catch((err) => {});
    },
    // async CALCULATE_ORDER({ commit, state }, data) {
    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `${state.token}`);
    //   const formdata = new FormData();
    //   formdata.append("tariff_id", data.tariff_id);
    //   formdata.append("amount", data.amount);
    //   formdata.append("start_date", data.start_date);
    //   formdata.append("profunion", data.profunion);
    //   formdata.append("company_id", data.company_id);

    //   const requestOptions = {
    //     method: "POST",
    //     headers: myHeaders,
    //     body: formdata,
    //     redirect: "follow",
    //   };

    //   return await fetch(
    //     `${process.env.VUE_APP_API_URL}/info/calculate/order`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((res) => {
    //       return res;
    //     })
    //     .catch((error) => {
    //       return error;
    //     });
    // },
    // async ADD_BILL({ commit, state }, data) {
    //   commit("MUTATE_BILL_FIELDS", data);

    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${state.token}`);
    //   const formdata = new FormData();

    //   formdata.append("number", state.bill_template.rs.value);
    //   formdata.append("name", state.bill_template.akb.value);
    //   formdata.append("bik", state.bill_template.bik.value);
    //   formdata.append("holder", state.bill_template.FIO.value);
    //   formdata.append("correspondent_acc", state.bill_template.ks.value);
    //   formdata.append("default", "0");
    //   formdata.append("orderId", state.registration_id);

    //   const requestOptions = {
    //     method: "POST",
    //     body: formdata,
    //     headers: myHeaders,
    //     redirect: "follow",
    //   };

    //   return await fetch(
    //     `${process.env.VUE_APP_API_URL}/registration/requisites/account`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((res) => {
    //       // this.dispatch("GET_REQUISITES");
    //       return res;
    //     });
    // },
    async ADD_CARD({ commit, state }, data) {
      commit("MUTATE_CARD_FIELDS", data);

      const myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${state.token}`);
      const formdata = new FormData();

      formdata.append("pan", state.card_template.pan);
      formdata.append("expdate", state.card_template.expdate);
      formdata.append("default", state.card_template.default);

      const requestOptions = {
        method: "POST",
        body: formdata,
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${process.env.VUE_APP_API_URL}/registration/requisites/card`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.success == true) {
            // this.dispatch("GET_REQUISITES");
          }
          if (res.success == false) {
          }
        })
        .catch((error) => error);
    },
    async GET_CARDS({ commit, state }) {
      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.token}`,
        },
        url: `${process.env.VUE_APP_API_URL}/cards/requesits`,
      }).then((res) => {
        commit("SET_CARDS", res);
      });
    },
    async GET_DOCUMENT_04031_STEP_ZERO({ commit, state }, data) {
      const formdata = new FormData();
      formdata.append("phone", data.phone.value);
      formdata.append("lastname", data.lastname.value);
      formdata.append("firstname", data.firstname.value);
      formdata.append("patronymic", data.patronymyc.value);
      formdata.append("birth", toDate(data.date_of_birth.value));
      formdata.append("birth_place", data.place_of_birth.value);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      return await fetch(
        `${process.env.VUE_APP_API_URL}/document/prepers`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          return result.data.link;
        })
        .catch((error) => {});
    },
    // async SEND_FIFTH_STEP({ commit, state }, data) {
    //   commit("MUTATE_FIFTH_STEP", data);

    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${state.token}`);
    //   const formdata = new FormData();
    //   formdata.append(
    //     "profunion",
    //     state.registration_form.fifth_step.prof_union.value
    //   );
    //   formdata.append(
    //     "want_profunion",
    //     state.registration_form.fifth_step.prof_union_notification.value
    //   );
    //   formdata.append("orderId", state.registration_id);

    //   const requestOptions = {
    //     method: "POST",
    //     body: formdata,
    //     headers: myHeaders,
    //     redirect: "follow",
    //   };

    //   return await fetch(
    //     `${process.env.VUE_APP_API_URL}/registration/profunion`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((res) => {
    //       return res;
    //     })
    //     .catch((error) => console.log("error", error));
    // },
    // async GET_EMPLOYERS({ commit, state }) {
    //   if (!state.employers.length) {
    //     return await axios({
    //       method: "POST",
    //       headers: {
    //         "Content-Type": "application/x-www-form-urlencoded",
    //       },
    //       url: `${process.env.VUE_APP_API_URL}/info/employers`,
    //     }).then((res) => {
    //       commit("SET_EMPLOYERS", res.data);
    //       return res;
    //     });
    //   }
    // },
    async SEND_SMS({ commit }, data) {
      data.phone = toServerPhone(data.phone);
      // return await axios.post(`${process.env.VUE_APP_API_URL}/sms/send`, data);
      return await apiRequest.function(
        "POST",
        "/sms/send",
        [],
        undefined,
        data
      );
    },
    async SEND_ASP_SMS({ commit }, id) {
      console.log(id);
      // return await axios.post(`${process.env.VUE_APP_API_URL}/sms/send`, data);
      return await apiRequest.function(
        "POST",
        "/sms/other/send",
        [],
        null,
        {
          doc_id: id,
        },
        { Authorization: `${getCookie("token")}` }
      );
    },
    async CHECK_ASP_CODE({ commit }, payload) {
      // return await axios.post(`${process.env.VUE_APP_API_URL}/sms/send`, data);
      return await apiRequest.function(
        "POST",
        "/sms/other/check",
        [],
        null,
        payload,
        { Authorization: `${getCookie("token")}` }
      );
    },
    async CHECK_EMAIL_CODE({ commit }, data) {
      const formdata = new FormData();
      formdata.append("email", data.login);
      formdata.append("code", data.code.replace(/\s/g, ""));
      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      return await fetch(
        `${process.env.VUE_APP_API_URL}/verification/email/check`,
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          return res;
        });
    },
    // async CHECK_CODE({ commit, state }, data) {
    //   const formdata = new FormData();
    //   formdata.append("phone", data.login);
    //   formdata.append("code", data.code.replace(/\s/g, ""));
    //   formdata.append("type", data?.type);
    //   if (data?.orderId) {
    //     formdata.append("orderId", data?.orderId);
    //   }
    //   const requestOptions = {
    //     method: "POST",
    //     body: formdata,
    //     redirect: "follow",
    //   };

    //   return await fetch(
    //     `${process.env.VUE_APP_API_URL}/verification/phone/check`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((res) => {
    //       return res;
    //     });
    // },
    async SET_SAVED_TOKENS({ commit, state }) {
      commit("SET_TOKENS");
    },
    async BASE64_TO_FILE({ commit, state }, dataurl) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    async BLOB_TO_FILE({ commit, state }, payload) {
      console.log(payload);
      payload.lastModifiedDate = new Date();
      payload.name = payload.type;
      return payload;
    },
    // async SEND_PHOTO({ commit, state }, data) {
    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${state.token}`);
    //   const formdata = new FormData();
    //   formdata.append("file", data?.value);
    //   formdata.append("type", data?.type);
    //   const requestOptions = {
    //     method: "POST",
    //     body: formdata,
    //     headers: myHeaders,
    //     redirect: "follow",
    //   };

    //   fetch(`${process.env.VUE_APP_API_URL}/registration/photo`, requestOptions)
    //     .then((response) => response.json())
    //     .then((res) => {
    //       if (res.success == false) {
    //       }
    //     })
    //     .catch((error) => console.log("error", error));
    // },
    // async SEND_SIXTH_STEP({ commit, state }, data) {
    //   for (const key in data) {
    //     if (Object.hasOwnProperty.call(data, key)) {
    //       const element = data[key];
    //       this.dispatch("SEND_PHOTO", element);
    //     }
    //   }
    // },
    // async SEND_SECOND_STEP({ commit, state }, data) {
    //   commit("MUTATE_SECOND_STEP", data);

    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${state.token}`);
    //   const formdata = new FormData();
    //   formdata.append("email", state.registration_form.second_step.email.value);
    //   formdata.append("viber", state.registration_form.second_step.viber.value);
    //   formdata.append(
    //     "whatsapp",
    //     state.registration_form.second_step.whatsapp.value
    //   );
    //   formdata.append(
    //     "telegram",
    //     state.registration_form.second_step.telegram.value
    //   );
    //   formdata.append(
    //     "facebook",
    //     state.registration_form.second_step.facebook.value
    //   );
    //   formdata.append(
    //     "ok",
    //     state.registration_form.second_step.odnoklassniki.value
    //   );
    //   formdata.append("vk", state.registration_form.second_step.vk.value);

    //   formdata.append(
    //     "fio_foreign_flagrelative",
    //     state.registration_form.second_step.fio_foreign_flagrelative.value
    //   );
    //   formdata.append(
    //     "foreign_husb_wife",
    //     state.registration_form.second_step.foreign_husb_wife.value
    //   );
    //   formdata.append(
    //     "fio_public_spouse",
    //     state.registration_form.second_step.fio_public_spouse.value
    //   );
    //   formdata.append(
    //     "foreign_relative",
    //     state.registration_form.second_step.foreign_relative.value
    //   );
    //   formdata.append(
    //     "fio_relative",
    //     state.registration_form.second_step.fio_relative.value
    //   );
    //   formdata.append(
    //     "contact_preferred_id",
    //     state.registration_form.second_step.notifications.value
    //   );

    //   const requestOptions = {
    //     method: "POST",
    //     body: formdata,
    //     headers: myHeaders,
    //     redirect: "follow",
    //   };

    //   return await fetch(
    //     `${process.env.VUE_APP_API_URL}/registration/contact`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((res) => {
    //       if (!res.success) {
    //         // return {
    //         //   success: false,
    //         //   error: {
    //         //     description: "Данный email уже использовался при регистрации",
    //         //   },
    //         // };
    //         // return {
    //         //   success: false,
    //         //   error: {
    //         //     description: "Данный viber уже использовался при регистрации",
    //         //   },
    //         // };
    //         // return {
    //         //   success: false,
    //         //   error: {
    //         //     description: "Данный whatsapp уже использовался при регистрации",
    //         //   },
    //         // };
    //         // return {
    //         //   success: false,
    //         //   error: {
    //         //     description: "Данный telegram уже использовался при регистрации",
    //         //   },
    //         // };
    //         return res;
    //       }
    //       return res;
    //     })
    //     .catch((error) => console.log("error", error));
    // },
    // async SEND_FIRST_STEP({ commit, state }, data) {
    //   commit("MUTATE_FIRST_STEP", data);

    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${state.token}`);
    //   const formdata = new FormData();
    //   formdata.append(
    //     "passport_serial",
    //     state.registration_form.first_step.pass_code.value
    //   );
    //   formdata.append(
    //     "passport_number",
    //     state.registration_form.first_step.pass_number.value
    //   );
    //   formdata.append(
    //     "passport_date",
    //     state.registration_form.first_step.issued.value
    //   );
    //   formdata.append(
    //     "passport_issued",
    //     state.registration_form.first_step.issued_by.value
    //   );
    //   formdata.append(
    //     "subdivision_code",
    //     state.registration_form.first_step.department_code.value
    //   );
    //   formdata.append("snils", state.registration_form.first_step.snils.value);
    //   formdata.append("inn", state.registration_form.first_step.inn.value);
    //   formdata.append(
    //     "faktadressfull",
    //     state.registration_form.first_step.place_of_residence.value
    //   );
    //   formdata.append(
    //     "actual_address",
    //     state.registration_form.first_step.registration_address_match.value
    //   );
    //   formdata.append(
    //     "regadressfull",
    //     state.registration_form.first_step.registration_address.value
    //   );
    //   formdata.append(
    //     "change_fio",
    //     state.registration_form.first_step.change_fio.value
    //   );
    //   formdata.append(
    //     "prev_fio",
    //     state.registration_form.first_step.prev_fio.value
    //   );
    //   formdata.append(
    //     "fio_change_date",
    //     state.registration_form.first_step.fio_change_date.value
    //   );
    //   const requestOptions = {
    //     method: "POST",
    //     body: formdata,
    //     headers: myHeaders,
    //     redirect: "follow",
    //   };

    //   return await fetch(
    //     `${process.env.VUE_APP_API_URL}/registration/passport`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((res) => {
    //       if (!res.success) {
    //         // return {
    //         //   success: false,
    //         //   error: {
    //         //     description: "Данный паспорт уже использовался при регистрации",
    //         //   },
    //         // };
    //         // return {
    //         //   success: false,
    //         //   error: {
    //         //     description:
    //         //       "Данный номер снилс уже использовался при регистрации",
    //         //   },
    //         // };
    //         // return {
    //         //   success: false,
    //         //   error: {
    //         //     description:
    //         //       "Данный номер инн уже использовался при регистрации",
    //         //   },
    //         // };
    //         return res;
    //       }
    //       return res;
    //     })
    //     .catch((error) => console.log("error", error));
    // },
    // async SEND_ZERO_STEP({ commit, state }, data) {
    //   commit("MUTATE_ZERO_STEP", data);

    //   const myHeaders = new Headers();
    //   myHeaders.append("Authorization", `Bearer ${state.token}`);
    //   const formdata = new FormData();
    //   formdata.append(
    //     "lastname",
    //     state.registration_form.zero_step.lastname.value
    //   );
    //   formdata.append(
    //     "firstname",
    //     state.registration_form.zero_step.firstname.value
    //   );
    //   formdata.append(
    //     "patronymic",
    //     state.registration_form.zero_step.patronymyc.value
    //   );
    //   formdata.append(
    //     "birth",
    //     state.registration_form.zero_step.date_of_birth.value
    //   );
    //   formdata.append(
    //     "birth_place",
    //     state.registration_form.zero_step.place_of_birth.value
    //   );
    //   formdata.append(
    //     "tariff_id",
    //     state.registration_form.creditionals.tariff_id.value
    //   );
    //   formdata.append(
    //     "start_date",
    //     state.registration_form.creditionals.date_of_issue.value
    //   );
    //   formdata.append("phone", state.registration_form.zero_step.phone.value);
    //   formdata.append(
    //     "amount",
    //     state.registration_form.creditionals.loan_sum.value
    //   );
    //   formdata.append("newLoan", data?.newLoan);

    //   const requestOptions = {
    //     method: "POST",
    //     body: formdata,
    //     headers: myHeaders,
    //     redirect: "follow",
    //   };

    //   return await fetch(
    //     `${process.env.VUE_APP_API_URL}/registration/main`,
    //     requestOptions
    //   )
    //     .then((response) => response.json())
    //     .then((res) => {
    //       if (!res.success && res.error.type == "UNAUTHENTICATED") {
    //         const refresh = this.dispatch("REFRESH_TOKEN");
    //         if (refresh.success) {
    //           this.dispatch("SEND_ZERO_STEP");
    //           return res;
    //         }
    //         return res;
    //       } else {
    //         commit("SET_NEW_TOKENS", res);
    //         return res;
    //       }
    //     })
    //     .catch((error) => {});
    // },
    async REFRESH_TOKEN({ commit, state }) {
      return await axios({
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${state.refresh_token}`,
        },
        url: `${process.env.VUE_APP_API_URL}/auth/refresh`,
      })
        .then((res) => {
          commit("SET_NEW_TOKENS", res.data);
          return res.data;
        })
        .catch((err) => {
          if (err.response.data.success == false) {
            // localStorage.setItem("token", "");
            // localStorage.setItem("refresh_token", "");
            // state.token = "";
            // state.refresh_token = "";
            return err.response;
          }
        });
    },
    async GET_TARIFFS({ commit, state }) {
      let res = await apiRequest.function("GET", "/info/products", [
        "SET_TARIFFS",
      ]);
    },
    async AUTH({ commit }, data) {
      const formdata = new FormData();
      formdata.append("login", data.login);
      formdata.append("password", data.password);

      const requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(`${process.env.VUE_APP_API_URL}/auth`, requestOptions)
        .then((response) => response.json())
        .then((res) => {
          commit("SET_AUTH_DATA", res);
          commit("SET_AUTH", res);
        });
      // .catch((error) => console.log("error", error));
    },
    async MESSENGER_AUTH({ commit }, data) {
      commit("SET_AUTH_DATA", data);
      commit("SET_AUTH_MESSENGER", "4444");
    },
  },
  modules: {},
});
