export default {
  SET_SERVER_ERROR({ commit, state }, { response, config }) {
    state.global_notifications.notification =
      `Что-то пошло не так 500` +
      "\n" +
      `url: ${config?.url}` +
      "\n" +
      `${JSON.parse(response?.request?.responseText).error.description}`;
    commit("SET_GLOBAL_NOTIFICATION", state.global_notifications.notification);
  },
};
