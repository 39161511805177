export default {
  SET_EMPLOYER_DOCUMENTS: (state, res) => {
    res.map((item) => {
      item.match = [];
      item.name = item.name.toLowerCase();
      item.preText = true;
      item.errorBag = [];

      if (item.name.includes("согласие на")) {
        item.name = item.name.slice(11);
      }
      if (item.name.includes("согласие")) {
        item.name = item.name.slice(8);
      }
      if (item.name.includes("получение кредитного отчета")) {
        console.log(item.name);
        item.preText = false;
      }
      if (
        item.name.includes(
          "работодателю на распространение персональных данных"
        )
      ) {
        console.log(item.name);
        item.preText = false;
      }
    });
    state.employer_documents = res;
  },
  SET_END_DOCUMENTS: (state, res) => {
    res.map((item) => {
      item.match = [];
      item.errorBag = [];
    });
    state.end_documents = res;
  },
};
