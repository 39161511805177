import apiRequest from "../../../middlewares/api-request";
import { getCookie, setCookie } from "../../../helpers/helpers";
import {
  toServerPhone,
  toDate,
  toServerDate,
} from "../../../helpers/converters";
export default {
  async SEND_FIRST_STEP({}, data) {
    console.log(data.step);
    const { loan_sum, tariff_id, date_of_issue } = data.creditionals;
    const {
      phone,
      lastname,
      firstname,
      patronymyc,
      place_of_birth,
      date_of_birth,
    } = data.step;
    let payload = {
      amount: loan_sum.value.split(" ").join(""),
      tariff_id: tariff_id.value,
      start_date: toServerDate(date_of_issue.value),
      phone: toServerPhone(phone.value),
      lastname: lastname.value,
      firstname: firstname.value,
      patronymic: patronymyc.value,
      birth: toServerDate(date_of_birth.value),
      birth_place: place_of_birth.value,
    };
    console.log(payload);
    return await apiRequest.function(
      "POST",
      "/step/main",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async SEND_SECOND_STEP({}, data) {
    const {
      department_code,
      inn,
      issued,
      issued_by,
      pass_code,
      pass_number,
      place_of_residence,
      registration_address,
      change_fio,
      prev_fio,
      fio_change_date,
      snils,
      registration_address_match,
    } = data;

    let payload = {
      passport_serial: pass_code.value,
      passport_number: pass_number.value,
      passport_date: toServerDate(issued.value),
      passport_issued: issued_by.value,
      subdivision_code: department_code.value,
      snils: snils.value,
      inn: inn.value,
      faktadressfull: place_of_residence.value,
      actual_address: registration_address_match.value?.[0] || "0",
      regadressfull: registration_address.value,
      change_fio: change_fio.value,
      fio_change_date: toServerDate(fio_change_date.value),
      prev_fio: prev_fio.value,
    };

    return await apiRequest.function(
      "POST",
      "/step/passport",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async SEND_THIRD_STEP({}, data) {
    const {
      email,
      fio_foreign_flagrelative,
      fio_public_spouse,
      fio_relative,
      foreign_husb_wife,
      foreign_relative,
      notifications,
    } = data;

    let payload = {
      email: email.value,
      viber: "",
      whatsapp: "",
      telegram: "",
      facebook: "",
      ok: "",
      vk: "",
      fio_foreign_flagrelative: fio_foreign_flagrelative.value,
      foreign_husb_wife: foreign_husb_wife.value,
      fio_public_spouse: fio_public_spouse.value,
      foreign_relative: foreign_relative.value,
      fio_relative: fio_relative.value,
      contact_preferred_id: JSON.stringify([1, 2, 5]),
    };
    return await apiRequest.function(
      "POST",
      "/step/contacts",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async SEND_FOURTH_STEP({}, data) {
    const { avg_earn, avg_spend, childs, company_id } = data;

    let payload = {
      income: avg_earn.value.split(" ").join(""),
      expenses: avg_spend.value.split(" ").join(""),
      dependents: childs.value,
      attestation_otb: "",
      company_id: company_id.value,
    };
    return await apiRequest.function(
      "POST",
      "/step/work",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async SEND_FIFTH_STEP({}, data) {
    let { prof_union, prof_union_notification } = data;
    const payload = {
      profunion: prof_union.value,
      want_profunion: prof_union_notification.value.length + "",
    };

    return await apiRequest.function(
      "POST",
      "/step/profunion",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async SEND_SIXTH_STEP({}, data) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `${getCookie("token")}`);
    let formdata = new FormData();
    formdata.append("type[]", "Селфи с паспортом");
    formdata.append("type[]", "Паспорт: разворот");
    formdata.append("type[]", "Паспорт: регистрация");
    formdata.append("file[0]", data.selfie.value, "[PROXY]");
    formdata.append("file[1]", data.front_side.value, "[PROXY]");
    formdata.append("file[2]", data.registration_page.value, "[PROXY]");

    let requestOptions = {
      method: "POST",
      body: formdata,
      headers: myHeaders,
      redirect: "follow",
    };

    return await fetch(
      `${process.env.VUE_APP_API_URL}/step/photos`,
      requestOptions
    )
      .then((res) => res)
      .catch((error) => error.response);
  },
  async GET_EMPLOYER_DOCUMETS() {
    return await apiRequest.function(
      "GET",
      "/info/stand/docs",
      ["SET_EMPLOYER_DOCUMENTS"],
      undefined,
      undefined
    );
  },
  async GET_END_DOCUMENTS() {
    return await apiRequest.function(
      "POST",
      "/step/end",
      ["SET_END_DOCUMENTS"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async APPEND_REG_BILL({}, data) {
    const { rs, akb, bik, FIO, bill_inn, ks } = data,
      payload = {
        number: rs.value,
        name: akb.value,
        bik: bik.value,
        holder: FIO.value,
        inn_holder: bill_inn.value,
        correspondent_acc: ks.value,
        default: "0",
      };
    return await apiRequest.function(
      "POST",
      "/step/requisites/bank/add",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async VERIFICATE_EMAIL({}, data) {
    return await apiRequest.function(
      "POST",
      "/mail/send",
      [],
      undefined,
      { email: data },
      { Authorization: `${getCookie("token")}` }
    );
  },
  async CHECK_VERIFICATE_EMAIL_CODE({}, data) {
    return await apiRequest.function(
      "POST",
      "/mail/check",
      [],
      undefined,
      data,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async CHANGE_LOAN_SETTINGS({}, data) {
    const payload = {
      amount: data.loan_sum.value?.split(" ").join(""),
      start_date: toServerDate(data.date_of_issue.value),
      tariff_id: data.tariff_id.value,
    };
    return await apiRequest.function(
      "POST",
      "/loan/settings/change",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
};
