<template>
  <main class="default-layout default">
    <!-- <picture class="default-bg">
      <source srcset="@/assets/img/home-bg-1920.png" type="image/png" />
      <source
        srcset="@/assets/img/home-bg-768.png"
        type="image/png"
        media="(min-width: 768px)"
      />
      <img
        src="@/assets/img/home-bg-768.png"
        class="default-bg"
        alt="Новогодний фон"
      />
    </picture> -->

    <!-- <picture class="default-bg" v-show="currPage == '/'">
      <source
        srcset="@/assets/img/home-bg-1920.png"
        type="image/png"
        media="(min-width:769px)"
      />
      <source
        srcset="@/assets/img/home-bg-768.png"
        type="image/png"
        media="(min-width:569px)"
      />
      <source
        srcset="@/assets/img/home-bg-568.png"
        type="image/png"
        media="(max-width:568px)"
      />
      <img class="default-bg" src="@/assets/img/home-bg-568.png" alt="" />
    </picture> -->

    <div class="reg-bg-1"></div>
    <div class="reg-bg-2"></div>

    <v-header>
      <div class="header__wrapper">
        <router-link
          to="/auth"
          class="header__link header__btn cta-btn"
          :class="{
            'btn-disabled':
              REGISTRATION_LAYOUT?.btns == 'disabled' &&
              this.$route.path == '/registration',
          }"
          >Войти</router-link
        >
        <!-- <router-link
          to="/registration"
          class="header__link header__btn cta-btn"
          :class="{
            'btn-disabled':
              REGISTRATION_LAYOUT?.btns == 'disabled' &&
              this.$route.path == '/registration',
          }"
          >Зарегистрироваться</router-link
        > -->
      </div>
    </v-header>

    <router-view />

    <v-footer></v-footer>
  </main>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "default-layout",
  computed: {
    ...mapGetters(["REGISTRATION_LAYOUT"]),
    currPage() {
      return this.$route.path;
    },
  },
  mounted() {},
};
</script>
<style lang="scss">
.default {
  position: relative;
  display: flex;
  flex-direction: column;
  main {
    flex: 1 1 auto;
  }
  .reg-bg-1 {
    position: absolute;
    content: "";
    width: 550px;
    height: 550px;
    left: -6%;
    top: -300px;
    background: #1816a399;
    opacity: 0.3;
    -webkit-filter: blur(30px);
    filter: blur(30px);
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    z-index: -1;
  }
  .reg-bg-2 {
    position: absolute;
    content: "";
    width: 1400px;
    height: 100vh;
    background: #b871ff;
    opacity: 0.3;
    -webkit-filter: blur(50px);
    filter: blur(50px);
    border-radius: 50%;
    top: 0;
    right: -24%;
    z-index: -4;
  }
}

.grecaptcha-badge {
  z-index: 999999999;
}

.default-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 80vh;
  height: auto;
  z-index: 0;
  &::after {
    content: "";
    width: 100vw;
    min-height: 221px;
    background: linear-gradient(180deg, #ffffff -56.19%, #ffffff 100%);
    z-index: 3;
    filter: blur(14px);
    position: absolute;
    top: 90%;
  }
}

.default-layout {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

@media (max-width: 1200px) {
  .default {
    position: relative;
    &::before {
    }
    &::after {
      width: 700px;
      height: 700px;
      top: 395px;
      right: -20%;
      // right: initial;
    }
  }
}

@media (max-width: 768px) {
  .default-bg {
    transform: scale(1.1);
    max-height: 100vh;
  }
}
@media (max-width: 575px) {
  .default {
    &::after {
      top: 350px;
    }
  }
}
@media (max-width: 420px) {
  .default-bg {
    min-height: 112vh;
    max-height: 112vh;
    &::after {
      width: 100vw;
      min-height: 111px;
      background: linear-gradient(180deg, #ffffff -56.19%, #ffffff 100%);
      z-index: 3;
      filter: blur(14px);
      position: absolute;
      top: 90%;
    }
  }
}
</style>
