<template>
  <label class="custom-otp-input-label" :for="attrs.id">
    <p class="custom-otp-input-label-text">{{ attrs.label }}</p>

    <div class="custom-otp-input-wrapper">
      <input
        :type="attrs.type"
        class="custom-otp-input"
        placeholder="_ _ _ _"
        :value="modelValue"
        :id="attrs.id"
        @input="$emit('update:modelValue', $event.target.value)"
        v-maska="`# # # #`"
      />
      <button class="custom-otp-input-append">
        <slot name="append"></slot>
      </button>
      <p class="custom-otp-input-error" v-show="errorBag.length">
        {{ errorBag[0]?.name }}
      </p>
    </div>
  </label>
</template>
<script>
export default {
  name: 'v-otp-input',
  props: {
    modelValue: { type: String, default: null },
    attrs: { type: Object, default: null },
    errorBag: { type: Array, default: () => [] }
  }
}
</script>
<style lang="scss">
.custom-otp-input-label {
}
.custom-otp-input-label-text {
}
.custom-otp-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.custom-otp-input {
  background-color: $white;
  font-size: 55px;
  line-height: 42px;
  max-width: 458px;
  width: 100%;
  cursor: pointer;
  height: 100px;
  padding: 23px 30px;
  text-align: center;
  color: $secondGray;
  border: 1px solid $secondGray;
  letter-spacing: 15px;
  &::placeholder {
    color: #a4a4a4;
  }
}
.custom-otp-input-append {
  cursor: pointer;
  font-size: 18px;
  line-height: 50px;
  color: $blue;
  border: none;
  margin-left: 33px;
  background-color: transparent;
  // border-bottom: 1px solid $blue;
  text-decoration: underline;
}
.custom-otp-input-error {
  position: absolute;
  top: 110%;
  left: 0px;
  color: $red;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  max-width: 100%;
  white-space: break-spaces;
}
</style>
