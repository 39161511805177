<template>
  <div class="dashboard-layout dashboard">
    <v-header class="dashboard__header-gradient standart">
      <div class="header__wrapper">
        <button class="header__link header__btn cta-btn" @click="logout">
          Выйти
        </button>
      </div>
    </v-header>

    <div class="dashboard__container">
      <div class="dashboard__breadcrumbs">
        <router-link to="/">Главная</router-link>
        <svg
          width="15"
          height="8"
          viewBox="0 0 15 8"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.8536 4.35355C15.0488 4.15829 15.0488 3.84171 14.8536 3.64645L11.6716 0.464466C11.4763 0.269204 11.1597 0.269204 10.9645 0.464466C10.7692 0.659728 10.7692 0.976311 10.9645 1.17157L13.7929 4L10.9645 6.82843C10.7692 7.02369 10.7692 7.34027 10.9645 7.53553C11.1597 7.7308 11.4763 7.7308 11.6716 7.53553L14.8536 4.35355ZM0 4.5H14.5V3.5H0V4.5Z"
            fill="black"
          />
        </svg>
        <router-link to="/dashboard">Личный кабинет</router-link>
      </div>
      <h1 class="dashboard__title">Личный кабинет</h1>

      <div class="dashboard__wrapper">
        <v-dashboard-sidebar></v-dashboard-sidebar>

        <router-view></router-view>
      </div>
    </div>
    <v-footer></v-footer>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import vDashboardSidebar from "@/components/v-dashboard-sidebar";
import { setCookie } from "../helpers/helpers";

export default {
  name: "dashboard-layout",
  components: {
    vDashboardSidebar,
  },
  methods: {
    ...mapActions([
      "GET_GENERAL_INFO",
      "GET_PROFILE",
      "LOGOUT",
      "GET_USER",
      "GET_STAGE",
      "GET_REABILITY_STATUS",
    ]),
    setCookie,
    async logout() {
      console.log("logout");
      await this.LOGOUT();
    },
  },
  data() {
    return {
      authorized: "",
    };
  },
  computed: {
    ...mapGetters(["GENERAL_INFO"]),
  },
  async mounted() {
    await this.GET_USER();
    await this.GET_STAGE().then(async (stage) => {
      console.log(stage);
      if (+stage.data !== 8) {
        const { data, status } = await this.GET_REABILITY_STATUS();
        if (data == 1) {
          this.$store.state.setZeroFields = true;
          setCookie("step", 1);
          this.$router.push("/registration");
        }
      }
      if (stage.data < 8 && stage.data !== 7) {
        setCookie("step", stage.data);
        this.$router.push("/registration");
        this.$store.state.global_notifications.notification =
          "У вас уже есть заявка продолжите её заполнение";
        this.$store.commit("SET_GLOBAL_NOTIFICATION");
      } else if (stage.data == 7) {
        setCookie("step", 6);
        this.$router.push("/registration_success");
        this.$store.state.global_notifications.notification =
          "У вас уже есть заявка продолжите её заполнение";
        this.$store.commit("SET_GLOBAL_NOTIFICATION");
      }
    });
  },
  created() {},
};
</script>
<style lang="scss">
.dashboard-layout {
  padding: 0 15px;
  background: #f6f6f6;
}
.dashboard {
  // .dashboard__header-gradient

  &__header-gradient {
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      width: 15vw;
      height: 12%;
      filter: blur(90px);
      z-index: 1;
      display: block;
      background-color: #b871ff;
    }
    &::before {
      position: absolute;
      content: "";
      width: 15vw;
      height: 12%;
      background: #1816a399;
      opacity: 0.6;
      left: 0;
      top: 0;
      -webkit-filter: blur(30px);
      display: block;
      filter: blur(30px);
      border-radius: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      z-index: 1;
    }
    .header__wrapper {
      z-index: 2;
    }
    .header__btn {
      max-width: initial;
      min-width: initial;
    }
  }
  // .dashboard__container

  &__container {
    @include container;
    margin-bottom: 60px;
  }

  // .dashboard__breadcrumbs

  &__breadcrumbs {
    a {
      margin-right: 15px;
      font-size: 18px;
      font-family: "Lato", "Roboto", sans-serif;
      &:last-child {
        margin-right: 0;
        margin-left: 15px;
      }
    }
  }

  // .dashboard__title

  &__title {
    @include h1;
    margin: 30px 0 45px 0;
    line-height: 35px;
  }

  // .dashboard__wrapper

  &__wrapper {
    display: flex;
    align-items: flex-start;
  }
}
@media (max-width: 1200px) {
  .dashboard {
    // .dashboard__wrapper

    &__wrapper {
      flex-direction: column;
    }
    main {
      width: 100%;
    }
    &__title {
      margin-bottom: 24px;
      margin-top: 20px;
      font-size: 35px;
    }
  }
}

@media (max-width: 768px) {
  .dashboard-layout {
  }
  .dashboard {
    // .dashboard__header-gradient

    &__header-gradient {
    }

    // .dashboard__container

    &__container {
    }

    // .dashboard__breadcrumbs

    &__breadcrumbs {
    }

    // .dashboard__title

    &__title {
      font-size: 26px;
      margin-bottom: 22px;
    }

    // .dashboard__wrapper

    &__wrapper {
    }
  }
  .header {
    // .header__wrapper

    &__wrapper {
    }

    // .header__link

    &__link {
    }

    // .header__btn

    &__btn {
    }
  }
  .cta-btn {
  }
}

@media (max-width: 575px) {
  .dashboard {
    &__title {
      font-size: 21px;
    }
  }
}
</style>
