<template>
  <label
    class="custom-input-label"
    :class="{ 'custom-input-label--disabled': attrs?.disabled }"
    :ref="attrs?.ref"
    :for="attrs.id"
    @focusout="focusOutHandler"
    @focus="focusHandler"
    tabindex="-1"
  >
    <p class="custom-input-label-text" v-if="attrs.label">
      {{ attrs.label }}
      <slot name="text_append"></slot>
    </p>
    <slot name="under"></slot>

    <div class="custom-input-wrapper">
      <span class="pre-text" v-if="this.$slots.pre_text">
        <slot name="pre_text"></slot>
      </span>
      <div class="custom-input-wrapper-container">
        <input
          tabindex="0"
          :type="attrs.type"
          class="custom-input"
          :value="modelValue"
          :placeholder="attrs.placeholder"
          v-maska="attrs.maska"
          :style="{
            'max-width': attrs.width + 'px',
            height: attrs.height + 'px',
          }"
          @input="
            $emit(
              'update:modelValue',
              $event.target.value.replace(/^ +| +$|( ) +/g, '$1')
            )
          "
          :maxlength="attrs.v_maxlength"
          :class="{
            'input--error': errorBag.length > 0,
            depressed: this.$attrs?.depressed,
          }"
          @focus="focusHandler"
          :disabled="attrs.disabled"
        />
        <p class="custom-input-error">
          <span
            v-show="
              !errorBag.filter(
                (item) =>
                  item.name ==
                  'Пользователя с таким номером не найдено. Оставить заявку на займ вы можете'
              ).length &&
              !errorBag.filter(
                (item) =>
                  item.name ==
                  'Данный паспорт уже использовался при регистрации'
              ).length
            "
            >{{ errorBag.length ? errorBag[0].name : "" }}</span
          >

          <span
            v-show="
              errorBag.filter(
                (item) =>
                  item.name ==
                  'Пользователя с таким номером не найдено. Оставить заявку на займ вы можете'
              ).length
            "
            >Пользователя с таким номером не найдено. Оставить заявку на займ вы
            можете <router-link to="/registration">тут</router-link></span
          >

          <span
            v-show="
              errorBag.filter(
                (item) =>
                  item.name ==
                  'Данный паспорт уже использовался при регистрации'
              ).length
            "
            >Паспортные данные уже зарегистрированы, вы можете
            <router-link to="/auth"> авторизоваться </router-link></span
          >
        </p>
        <!-- <p class="custom-input-under" :ref="attrs.id" v-if="this.$slots.under2">
          <slot name="under2"></slot>
        </p> -->

        <div
          class="custom-input-suggestions"
          v-show="this.focus && modelValue?.length > 1 && suggestions?.length"
        >
          <slot name="suggestions"></slot>
        </div>
      </div>
      <slot name="inner"></slot>
      <button
        class="custom-input-append"
        tabindex="-1"
        v-if="this.$slots.append"
      >
        <slot name="append"></slot>
      </button>
    </div>
  </label>
</template>
<script>
export default {
  name: "v-input",
  props: {
    modelValue: [String, Object, Array],
    attrs: {
      type: Object,
      default() {
        return {};
      },
    },
    errorBag: {
      type: Array,
      default() {
        return [];
      },
    },
    suggestions: {
      type: Array,
      default() {
        return [];
      },
    },
    isDirty: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      focus: false,
    };
  },
  methods: {
    focusOutHandler() {
      this.focus = false;
    },
    focusHandler() {
      this.focus = true;
    },
    updateModelValue(e, val) {
      let regex = this.attrs.regex;
      // if (this.attrs?.regex == "noLetters") {
      //   regex = /^([A-Z]+, )?$/;
      //   this.$emit("update:modelValue", val); }
      if (this.attrs?.regex) {
        this.$emit("update:modelValue", e.target.value);
        // e.target.value = val
        //   .replace(regex, "")
        //   .replace(/^ +| +$|( ) +/g, "$1")
        //   .toUpperCase();
      } else this.$emit("update:modelValue", val);
    },
    // selectSuggestions(suggest, field) {
    //   // Эмитить ничего не надо меняй просто modelValue))
    //   if ((field = "department-code")) {
    //     this.$emit("update:modelValue", suggest.data.code);
    //   }
    // },
  },
  computed: {},
  mounted() {},
  updated() {},
  beforeUpdate() {},
  watch: {},
};
</script>
<style lang="scss">
.custom-input-suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: $white;
  z-index: 2;
  width: 100%;
  max-height: 300px;
  overflow: auto;
  border: 1px solid $thirdGray;
  //   &::-webkit-scrollbar {
  //   width: 5px;
  //   background-color: $secondRed;
  // }
  // &::-webkit-scrollbar-track {
  //   background: $thirdGray;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background-color: $seventhGray;
  //   border: 3px solid $secondRed;
  // }
}
.custom-input-suggestions::-webkit-scrollbar {
  width: 4px;
  background-color: $secondRed;
}
.custom-input-suggestions::-webkit-scrollbar-track {
  background: $thirdGray;
}
.custom-input-suggestions::-webkit-scrollbar-thumb {
  background-color: $seventhGray;
  border: 2px solid $secondRed;
}
.custom-input-suggestions-item {
  font-size: 14px;
  line-height: 130%;
  text-align: left;
  border-bottom: 1px solid $thirdGray;
  &:last-child {
    border-bottom: none;
  }
}
.pre-text {
  margin-right: 10px;
  white-space: nowrap;
  font-family: "Lato", "Roboto", sans-serif;
}
.custom-input-label {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  white-space: nowrap;
  &:last-child {
    margin-bottom: 0;
  }
}
.custom-input-under {
  font-size: 14px;
  color: $secondGray;
  margin-left: 19px;
  margin-top: 6px;
}
.custom-input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
}
.custom-input-wrapper-container {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
}
.custom-input-error {
  position: absolute;
  top: 100%;
  left: 19px;
  color: $red;
  font-size: 12px;
  font-family: "Lato", sans-serif;
  max-width: 100%;
  white-space: break-spaces;
  span,
  a {
    color: inherit;
  }
  a {
    border-bottom: 1px solid $red;
    padding-bottom: 1px;
  }
}
.custom-input-label-text {
  font-size: 16px;
  line-height: 25px;
  color: $secondGray;
  margin-bottom: 12px;
  font-family: "AkzidenzGroteskPro-Ext", sans-serif;
  display: flex;
  align-items: center;
}
.custom-input-label-text-append {
  margin-left: 8px;
}
.custom-input {
  background-color: $white;
  border-radius: 3px;
  border: 1px solid $fifthGray;
  outline: none;
  padding: 8px 19px;
  height: 43px;
  width: 100%;
  font-family: "Lato", sans-serif;
}
.custom-input-append {
  margin-left: 10px;
  color: $blue;
  border: none;
  padding: 0;
  font-size: 16px;
  line-height: 25px;
  background-color: transparent;
  white-space: nowrap;
  cursor: pointer;
  pointer-events: all;
}
.input--error {
  border: 1px solid $red;
}
.custom-input.depressed {
  background-color: rgb(104, 30, 178, 0.15);
  border: none;
}

.custom-input-label--disabled {
  pointer-events: none;

  input {
    background-color: rgb(104, 30, 178, 0.15);
    border: none;
  }
}

@media (max-width: 1600px) {
  .custom-input-label {
  }
  .custom-input-label-text {
    font-size: 14px;
  }
  .custom-input-wrapper {
  }
  .pre-text {
  }
  .custom-input-wrapper-container {
  }
  .custom-input {
    height: 40px;
  }
  .custom-input-error {
  }
  .custom-input-append {
  }
}
@media (max-width: 1200px) {
  .custom-input-label {
  }
  .custom-input-label-text {
  }
  .custom-input-wrapper {
  }
  .pre-text {
  }
  .custom-input-wrapper-container {
  }
  .custom-input {
  }
  .custom-input-error {
  }
  .custom-input-append {
  }
}
@media (max-width: 768px) {
  .custom-input-label {
  }
  .custom-input-label-text {
    white-space: initial;
    font-size: 13px;
  }
  .custom-input-wrapper {
  }
  .pre-text {
  }
  .custom-input-wrapper-container {
  }
  .custom-input {
  }
  .custom-input-error {
  }
  .custom-input-append {
  }
}
@media (max-width: 575px) {
  .custom-input-label {
  }
  .custom-input-label-text {
  }
  .custom-input-wrapper {
  }
  .pre-text {
  }
  .custom-input-wrapper-container {
  }
  .custom-input {
  }
  .custom-input-error {
  }
  .custom-input-append {
  }
}
</style>
