export default {
  SET_USER(state, res) {
    state.user = res;
  },
  SET_EMPLOYERS: (state, res) => {
    Array.from(res).map((item) => {
      if (item.name !== "Инфраструктура") {
        Object.values(item.companies).map((item) => {
          if (
            !state.employers.filter((empl) => empl.name == item.name).length
          ) {
            state.employers.push(item);
          }
        });
      }
    });
  },
  SET_EMPLOYER_TARIFFS: (state, res) => {
    if (typeof res == "string") {
      state.global_notifications.notification = "Нет подходящих тарифов";
      state.tariffs = [];
      return;
    }

    state.tariffs = res.sort(function (a, b) {
      return +a?.number - +b?.number;
    });
    const colors = ["#00015D", "#00649C", "#681EB2"];
    const stock = "#ED2F41";
    // const saved_form = JSON.parse(localStorage.getItem("saved_form"));
    let maxTariffAmount = Math.max(
        ...state.tariffs.map((item) => item?.max_amount)
      ),
      maxTariff = state.tariffs.filter(
        (item) => item?.max_amount == maxTariffAmount
      )?.[0];

    for (
      let idx = 0, colorsIdx = 0;
      idx < state.tariffs.length;
      idx++, colorsIdx++
    ) {
      let colorLimit = 3;
      if (colorsIdx >= colorLimit) {
        colorsIdx = 0;
      }
      state.tariffs[idx].head_color = colors[colorsIdx];
    }
    state.tariffs
      .filter((item) => +item?.number <= 20)
      ?.map((item) => (item.head_color = stock));

    // for (let idx = 0; idx < state.tariffs.length; idx++) {
    //   const color = colors[idx];
    //   if (color == undefined) {
    //     idx = 0;
    //   }
    //   console.log(color);
    // }
  },
  SET_REBID_DOCUMENTS: (state, res) => {
    console.log(res);
    let documents = [];
    for (const key in res) {
      if (Object.hasOwnProperty.call(res, key)) {
        const element = res[key];

        element.match = [];
        element.name = element.name.toLowerCase();
        element.preText = true;
        element.errorBag = [];

        if (element.name.includes("согласие на")) {
          element.name = element.name.slice(11);
        }
        if (element.name.includes("согласие")) {
          element.name = element.name.slice(8);
        }
        if (element.name.includes("получение кредитного отчета")) {
          element.preText = false;
        }
        if (
          element.name.includes(
            "работодателю на распространение персональных данных"
          )
        ) {
          element.preText = false;
        }
        documents.push(element);
      }
    }
    state.rebid_documents = documents;
  },
  SET_REABILITY_STATUS: (state, res) => {
    state.reability_status = res;
  },
};
