// import { string } from "../../../helpers/converters";

export default {
  USER(state) {
    return state.user;
  },
  EMPLOYERS(state) {
    return state.employers;
  },
  REBID_DOCUMENTS(state) {
    return state.rebid_documents;
  },
  REABILITY_STATUS(state) {
    return state.reability_status;
  },
};
