import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import components from "@/components/UI";
import Maska from "maska";
import { VueReCaptcha } from "vue-recaptcha-v3";
import noDigitsDerictive from "@/directives/noDigits";
import jumpOverInput from "@/directives/jumpOverInput";
import jumpFromEmptyInput from "@/directives/jumpFromEmptyInput";
import spacer from "@/directives/spacer";

const app = createApp(App);

components.forEach((component) => {
  app.component(component.name, component);
});

app.directive("noDigits", noDigitsDerictive);
app.directive("spacer", spacer);
app.directive("jumpOver", jumpOverInput);
app.directive("jumpFromEmpty", jumpFromEmptyInput);
console.log(process.env);
app
  .use(VueReCaptcha, { siteKey: process.env.VUE_APP_CAPTCHA_SITE_KEY })
  .use(Maska)
  .use(store)
  .use(router)
  .mount("#app");
