// import { string } from "../../../helpers/converters";

export default {
  END_DOCUMENTS(state) {
    return state.end_documents;
  },
  EMPLOYER_DOCUMENTS(state) {
    return state.employer_documents;
  },
};
