<template>
  <div></div>
</template>
<script>
export default {
  name: 'v-bill-list'
}
</script>
<style lang="scss">
</style>
