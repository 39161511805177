import { getCookie } from "@/helpers/helpers";
import apiRequest from "@/middlewares/api-request";
import {
  toDate,
  dateReverse,
  toServerPhone,
  toServerDate,
} from "../../../helpers/converters";

export default {
  async SEND_REBID_BID({ commit, state }, data) {
    return await apiRequest.function(
      "POST",
      "/lk/loan/repeat/create",
      [],
      undefined,
      data,
      { Authorization: `${getCookie("token")}` }
    );
    // const myHeaders = new Headers();
    // myHeaders.append("Authorization", `Bearer ${getCookie("token")}`);
    // const formdata = new FormData();
    // formdata.append(data);
    // // formdata.append("tariff_id", tariff_id);
    // // formdata.append("amount", +loan_sum.split(" ").join(""));
    // // formdata.append("income", +avg_earn.split(" ").join(""));
    // // formdata.append("expenses", +avg_spend.split(" ").join(""));
    // // formdata.append("dependents", childs);
    // // formdata.append("company_id", company_id);
    // // formdata.append("profunion", prof_union);
    // // formdata.append("code", code);
    // // formdata.append("user_id", state.general_info?.id);
    // // formdata.append("newLoan", 1);

    // const requestOptions = {
    //   method: "POST",
    //   body: formdata,
    //   headers: myHeaders,
    //   redirect: "follow",
    // };

    // return await fetch(
    //   `${process.env.VUE_APP_API_URL}/repeatLoans/create`,
    //   requestOptions
    // )
    //   .then((response) => response.json())
    //   .then((res) => {
    //     // commit("SET_NEW_TOKENS", res);
    //     return res;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  },
  async GET_DEFAULT_SETTLEMENT() {
    return await apiRequest.function(
      "POST",
      "/lk/general/get_default_settlement",
      [],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async REBID_APPEND_ACCOUNT({}, data) {
    const { rs, akb, bik, FIO, ks, bill_inn } = data,
      payload = {
        number: rs.value,
        name: akb.value,
        bik: bik.value,
        holder: FIO.value,
        correspondent_acc: ks.value,
        inn_holder: bill_inn.value,
        default: "0",
      };
    return await apiRequest.function(
      "POST",
      "/lk/loan/repeat/requisites/account/add",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async CHECK_REBID_CODE({ commit, state }, data) {
    const formdata = new FormData();
    formdata.append("phone", data.login);
    formdata.append("code", data.code.replace(/\s/g, ""));
    if (data?.orderId) {
      formdata.append("orderId", data?.orderId);
    }
    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    return await fetch(
      `${process.env.VUE_APP_API_URL}/verification/phone/check`,
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        return res;
      });
  },
  async SEND_SECOND_STEP_REBID(
    { commit, state },
    {
      pass_code,
      pass_number,
      issued,
      issued_by,
      place_of_residence,
      registration_address,
      department_code,
    }
  ) {
    const payload = {
      passport_serial: pass_code.value,
      passport_number: pass_number.value,
      passport_date: toServerDate(issued.value),
      passport_issued: issued_by.value,
      subdivision_code: department_code.value,
      faktadressfull: place_of_residence.value,
      regadressfull: registration_address.value,
    };
    return await apiRequest.function(
      "POST",
      "/lk/loan/repeat/passport",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async SEND_THIRD_STEP_REBID({ commit, state }, { employer, prof_union }) {
    console.log(employer);
    const payload = {
      company_id: employer.company_id,
      profunion: prof_union.value,
    };
    return await apiRequest.function(
      "POST",
      "/lk/loan/repeat/work",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async SEND_FOURTH_STEP_REBID(
    { commit, state },
    { loan_sum, date_of_issue, tariff_id }
  ) {
    const payload = {
      amount: loan_sum.value.split(" ").join(""),
      start_date: toServerDate(date_of_issue.value),
      tariff_id: tariff_id.value,
    };
    return await apiRequest.function(
      "POST",
      "/lk/loan/repeat/settings",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async REBID_ID({ commit }, orderId) {
    commit("SET_REBID_ID", orderId);
  },
  async CAN_REBID() {
    return await apiRequest.function(
      "POST",
      "/lk/canSendNewLoan",
      [],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async PASS_CHANGES({}, data) {
    return await apiRequest.function(
      "POST",
      "/lk/loan/repeat/main",
      [],
      undefined,
      data,
      { Authorization: `${getCookie("token")}` }
    );
  },
};
