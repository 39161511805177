export default {
  mounted (el, binding, vnode) {
    el.onkeydown = function (e) {
      if (
        e.key == 'Backspace' &&
        e.target.value.length == 0 &&
        el.nextElementSibling.childNodes[3].childNodes[1].childNodes[0]
          .tagName == 'INPUT'
      ) {
        setTimeout(() => {
          const $el =
            el.previousElementSibling.childNodes[3].childNodes[1].childNodes[0]
          $el.focus()
        }, 1)
      }
    }
  }
}
