<template>
  <div
    class="tariff__list"
    :class="{
      'tariff__list-over2': TARIFFS.length == 2,
      'tariff__list-over1': TARIFFS.length == 1,
      'tariff__list-home': this.$route.path == '/',
    }"
  >
    <v-tariff-item
      @selectedTariff="selectedTariff"
      @selectedTariffFromAPI="selectedTariffFromAPI"
      @selectedTariffFromHome="selectedTariff"
      v-for="(item, idx) in sortedTariffs"
      :key="idx"
      :data="item"
      :idx="idx"
      :ref="'tariff_' + idx"
      :class="{
        'tariff__item-over2': TARIFFS.length == 2,
        'tariff__item-over1': TARIFFS.length == 1,
        tariff__home: this.$route.path == '/',
        'stock-tariff': +item?.number <= 20,
      }"
    >
    </v-tariff-item>
  </div>
</template>
<script>
import vTariffItem from "./v-tariff-item.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "v-tariff-list",
  components: {
    vTariffItem,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["TARIFFS"]),
    sortedTariffs() {
      return Array.from(this.TARIFFS).sort(function (a, b) {
        return +a?.number - +b?.number;
      });
    },
  },
  methods: {
    ...mapActions(["GET_TARIFFS", "GET_EMPLOYER_TARIFFS"]),
    selectedTariff(data) {
      if (this.TARIFFS == "Нет подходящих тарифов") {
        return;
      }
      this.TARIFFS.map((item) => (item.selected = false));
      data.selected = true;
      this.$emit("selectedTariff", data);
    },
    async selectedTariffFromAPI() {
      if (this.TARIFFS == "Нет подходящих тарифов") {
        return;
      }
      const filtred = this.TARIFFS?.filter((item) => item.selected == true);
      // if (!filtred.length) {
      //   this.$emit("selectedTariff", this.TARIFFS[0]);
      //   return;
      // }
      if (
        this.$route.path == "/" ||
        (localStorage.getItem("home_tariff") !== "undefined" &&
          localStorage.getItem("home_tariff") !== "null" &&
          localStorage.getItem("home_tariff") !== null &&
          localStorage.getItem("home_tariff") !== undefined)
      ) {
      } else {
        this.$emit("selectedTariff", filtred?.[0]);
      }
    },
  },
  async mounted() {
    // await this.GET_TARIFFS();
    let employer = JSON.parse(localStorage.getItem("saved_form"))?.third_step
      ?.company_id?.value;
    if (
      (employer && this.$route.path == "/registration") ||
      this.$route.path == "/bids"
    ) {
      if (employer == undefined || employer == "") await this.GET_TARIFFS();
      else await this.GET_EMPLOYER_TARIFFS(employer);
    } else {
      if (!this.TARIFFS.length) {
        await this.GET_TARIFFS();
      }
    }
    await this.selectedTariffFromAPI();
  },
};
</script>
<style lang="scss">
.tariff {
  // .tariff__list

  &__list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 21px;
    &::-webkit-scrollbar {
      height: 5px;
      background-color: $secondRed;
    }
    &::-webkit-scrollbar-track {
      background: $thirdGray;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $seventhGray;
      border: 3px solid $secondRed;
    }
  }

  // .tariff__item

  &__item {
    cursor: pointer;
    color: $white;
    padding: 22px 43px;
    border-radius: 5px;
    max-width: 32%;
    width: 100%;
    transition: 0.4s;
    opacity: 0.5;
    position: relative;
    &:first-child {
      background: rgba(0, 100, 156, 1);
    }
    &:nth-child(2) {
      background-color: rgba(0, 1, 93, 1);
    }
    &:nth-child(3) {
      background-color: rgba(104, 30, 178, 1);
    }
    &:last-child {
      margin-right: 0;
    }
    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 100%;
      opacity: 0;
      height: 100%;
      display: flex;
      border: 2px solid #fff;
      border-radius: 2px;
      transition: 0.3s ease;
    }
    &--active {
      opacity: 1;
      box-shadow: 0px 20px 20px rgba(48, 46, 186, 0.25);
      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        border: 2px solid #080979;
        border-radius: 5px;
        z-index: 0;
      }
    }
    &:hover {
      &::after {
        opacity: 1;
        box-shadow: 0px 0px 10px rgba(48, 46, 186, 1);
      }
    }
  }

  .stock-tariff {
  }

  // .tariff__item-head

  &__item-head {
  }

  // .tariff__item-title

  &__item-title {
    @include h4;
    line-height: 35px;
    font-size: 25px;
    margin-bottom: 18px;
    display: flex;
    align-items: flex-end;
    color: $white;
    strong {
      font-family: "AkzidenzGroteskPro-MdEx", sans-serif;
      font-weight: 500;
    }
    span {
      font-weight: 400;
      font-family: "AkzidenzGroteskPro-Ext", sans-serif;
      margin-right: 8px;
    }
  }

  // .tariff__item-condition

  &__item-condition {
    font-size: 18px;
    line-height: 100%;
    span {
      font-weight: 400;
      font-family: "AkzidenzGroteskPro-Ext", sans-serif;
    }
    strong {
      font-family: "AkzidenzGroteskPro-MdEx", sans-serif;
      font-weight: 500;
    }
  }

  // .tariff__item-divider

  &__item-divider {
    margin: 18px 0;
  }

  // .tariff__item-body

  &__item-body {
  }

  // .tariff__item-percent

  &__item-percent {
    line-height: 130%;
    font-size: 16px;
    font-family: "Lato", sans-serif;
    font-weight: 500;
  }

  // .tariff__item-info

  &__item-info {
    margin-top: 18px;
    font-size: 18px;
    line-height: 140%;
    font-family: "Lato", "Roboto", sans-serif;
  }

  // .tariff__item-over1

  &__item-over1 {
    max-width: 32%;
    margin: 0 auto !important;
  }
  // .tariff__item-over2

  &__item-over2 {
    max-width: 32%;
    margin-right: 20px;
  }
  // .tariff__list-over1

  &__list-over1 {
    justify-content: center;
  }
  // .tariff__list-over2

  &__list-over2 {
    justify-content: space-between;
    article {
      min-width: 49% !important;
    }
  }
  // .tariff__home
  &__home {
    opacity: 1;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.stock-tariff {
  overflow: hidden;
  .tariff__item--active {
    opacity: 0.1;
  }
  .new_year_bg {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-size: cover;
  }
}

@media (max-width: 1600px) {
  .tariff {
    // .tariff__list

    &__list {
    }

    // .tariff__item

    &__item {
    }

    // .tariff__item-head

    &__item-head {
    }

    // .tariff__item-title

    &__item-title {
      margin-bottom: 16px;
      span {
      }
    }

    // .tariff__item-condition

    &__item-condition {
      span {
      }
    }

    // .tariff__item-divider

    &__item-divider {
    }

    // .tariff__item-body

    &__item-body {
    }

    // .tariff__item-percent

    &__item-percent {
    }

    // .tariff__item-info

    &__item-info {
      margin-top: 16px;
      font-size: 16px;
    }
  }
}
@media (max-width: 1200px) {
  .tariff {
    // .tariff__list

    &__list {
    }

    // .tariff__item

    &__item {
      padding: 12px 20px;
    }

    // .tariff__item-head

    &__item-head {
    }

    // .tariff__item-title

    &__item-title {
      font-size: 22px;
      display: inline;
      line-height: 130%;
      span {
      }
    }

    // .tariff__item-condition

    &__item-condition {
      font-size: 16px;
      margin-top: 10px;
      span {
      }
    }

    // .tariff__item-divider

    &__item-divider {
    }

    // .tariff__item-body

    &__item-body {
    }

    // .tariff__item-percent

    &__item-percent {
      font-size: 16px;
    }

    // .tariff__item-info

    &__item-info {
      margin-top: 8px;
      font-size: 16px;
    }
  }
}
@media (max-width: 768px) {
  .tariff {
    // .tariff__list

    &__list {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    // .tariff__item

    &__item {
      max-width: initial;
      margin-bottom: 15px;
      width: 48%;
      margin-right: 0;
    }

    // .tariff__item-head

    &__item-head {
    }

    // .tariff__item-title

    &__item-title {
      font-size: 16px;
      margin-bottom: 7px;
      span {
      }
    }

    // .tariff__item-condition

    &__item-condition {
      font-size: 15px;
      span {
      }
    }

    // .tariff__item-divider

    &__item-divider {
    }

    // .tariff__item-body

    &__item-body {
    }

    // .tariff__item-percent

    &__item-percent {
      font-size: 15px;
    }

    // .tariff__item-info

    &__item-info {
      margin-top: 7px;
    }
    // .tariff__home

    &__home {
    }
    // .tariff__list-home

    &__home {
    }
  }
}
@media (max-width: 575px) {
  .tariff {
    // .tariff__list

    &__list {
      flex-direction: column;
    }

    // .tariff__item

    &__item {
      width: 100%;
    }

    // .tariff__item-head

    &__item-head {
    }

    // .tariff__item-title

    &__item-title {
      span {
      }
    }

    // .tariff__item-condition

    &__item-condition {
      span {
      }
    }

    // .tariff__item-divider

    &__item-divider {
    }

    // .tariff__item-body

    &__item-body {
    }

    // .tariff__item-percent

    &__item-percent {
    }

    // .tariff__item-info

    &__item-info {
    }
  }
}
</style>
