<template>
  <aside class="dashboard-sidebar">
    <div
      class="dashboard-sidebar__container"
      :class="{
        'dashboard-sidebar--active': this.visible,
      }"
    >
      <div
        class="dashboard-sidebar__link"
        :class="{
          'dashboard-sidebar__link--active': index == 0 && windowWidth <= 1200,
          'dashboard-sidebar__link--active1':
            windowWidth > 1 && this.$route.meta.name == link.name,
        }"
        v-for="(link, index) in links"
        :key="index"
        @click="sidebarNavigate(link, index)"
        :ref="link?.ref"
      >
        <span>
          {{ link.name }}
          <svg
            width="23"
            height="24"
            viewBox="0 0 23 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            v-if="link.attention"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M23 12C23 18.3513 17.8513 23.5 11.5 23.5C5.14873 23.5 0 18.3513 0 12C0 5.64873 5.14873 0.5 11.5 0.5C17.8513 0.5 23 5.64873 23 12ZM12.3598 4.69922H10.3398V10.5192L10.9198 14.9192H11.7998L12.3598 10.4992V4.69922ZM10.3598 18.4992H12.3598V16.4992H10.3598V18.4992Z"
              fill="#FF8266"
            />
          </svg>
        </span>

        <div class="arrow" v-if="index == 0">
          <svg
            width="15"
            height="10"
            viewBox="0 0 15 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2 2L7.5 7.5L13 2" stroke="white" stroke-width="3" />
          </svg>
        </div>
      </div>
    </div>

    <!-- <v-popup @closePopup="closePopup" :visible="this.visible">
      <template v-slot:popup-body>
        <div class="dashboard-sidebar__container-popup">
          <router-link
            @click="closePopup"
            class="dashboard-sidebar__link"
            to="/current_loan"
            >Текущий займ
          </router-link>
          <router-link
            @click="closePopup"
            class="dashboard-sidebar__link"
            to="/bids"
            >Заявки
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23 12C23 18.3513 17.8513 23.5 11.5 23.5C5.14873 23.5 0 18.3513 0 12C0 5.64873 5.14873 0.5 11.5 0.5C17.8513 0.5 23 5.64873 23 12ZM12.3598 4.69922H10.3398V10.5192L10.9198 14.9192H11.7998L12.3598 10.4992V4.69922ZM10.3598 18.4992H12.3598V16.4992H10.3598V18.4992Z"
                fill="#FF8266"
              />
            </svg>
          </router-link>
          <router-link
            @click="closePopup"
            class="dashboard-sidebar__link"
            to="/loan_history"
            >История займов
          </router-link>
          <router-link
            @click="closePopup"
            class="dashboard-sidebar__link"
            to="/cards_and_accounts"
            >Карты и счета
            <svg
              width="23"
              height="24"
              viewBox="0 0 23 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M23 12C23 18.3513 17.8513 23.5 11.5 23.5C5.14873 23.5 0 18.3513 0 12C0 5.64873 5.14873 0.5 11.5 0.5C17.8513 0.5 23 5.64873 23 12ZM12.3598 4.69922H10.3398V10.5192L10.9198 14.9192H11.7998L12.3598 10.4992V4.69922ZM10.3598 18.4992H12.3598V16.4992H10.3598V18.4992Z"
                fill="#FF8266"
              />
            </svg>
          </router-link>
          <router-link
            @click="closePopup"
            class="dashboard-sidebar__link"
            to="/profile"
            >Профиль
          </router-link>
          <router-link
            @click="closePopup"
            class="dashboard-sidebar__link"
            to="/documentation"
            >Документы
          </router-link>
          <router-link
            @click="closePopup"
            class="dashboard-sidebar__link"
            to="/photos"
            >Фотографии
          </router-link>
        </div>
      </template>
    </v-popup> -->
  </aside>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import gsap from "gsap";
export default {
  name: "v-dashboard-sidebar",
  data() {
    return {
      visible: false,
      links: [
        {
          name: "Текущий займ",
          to: "current_loan",
          attention: false,
        },
        {
          name: "Заявки",
          to: "bids",
          attention: true,
        },
        {
          name: "История займов",
          to: "loan_history",
          attention: false,
        },
        {
          name: "Карты и счета",
          to: "cards_and_accounts",
          attention: true,
        },
        {
          name: "Профиль",
          to: "profile",
          attention: false,
        },
        {
          name: "Документы",
          to: "documentation",
          attention: false,
        },
        {
          name: "Фотографии",
          to: "photos",
          attention: false,
          ref: "sidebar_photos",
        },
      ],
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    ...mapActions(["GET_PHOTOS"]),
    openSidebarNavigate(data, index) {},
    closeSidebarNavigate(data, index) {},
    sidebarNavigate(data, index) {
      this.windowWidth = window.innerWidth;
      if (this.windowWidth <= 1200) {
        if (this.$route.meta.name == data.name && !this.visible) {
          this.animateSidebar("open");
          this.visible = true;
        } else {
          this.animateSidebar("close");
          this.visible = false;
        }
        this.links.splice(0, 0, this.links.splice(index, 1)[0]);
      }

      this.$router.push(data.to);
    },
    animateSidebar(action) {
      const tl = gsap.timeline();

      if (action == "open") {
        tl.to(".dashboard-sidebar__container", 0.3, { height: 435 });
        return;
      }
      if (action == "close") {
        tl.to(".dashboard-sidebar__container", 0.3, { height: 65 });
      }
    },
    checkAttention(arr) {
      if (arr.length) {
        if (arr.filter((item) => item.status == 3).length) {
          this.links.filter((item) => item.to == "photos")[0].attention = true;
        } else {
          this.links.filter((item) => item.to == "photos")[0].attention = false;
        }
      }
    },
    // openPopup() {
    //   this.visible = true;
    // },
    // closePopup() {
    //   this.visible = false;
    // },
  },
  computed: {
    ...mapGetters(["PHOTOS", "PHOTOS_COMPLUSION"]),
  },
  mounted() {
    this.$nextTick(async () => {
      if (!this.PHOTOS.length) await this.GET_PHOTOS();
      this.checkAttention(this.PHOTOS);
      // if (this.PHOTOS_COMPLUSION) {
      //   this.$router.push({ name: "photos" });
      // }
    });
  },
  watch: {
    PHOTOS: {
      handler() {
        this.checkAttention(this.PHOTOS);
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.dashboard-sidebar {
  max-width: 337px;
  width: 100%;
  margin-right: 70px;
  // .dashboard-sidebar__container

  &__container {
    padding: 26px 40px;
    background-color: $white;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    .dashboard-sidebar__link {
      span {
        display: flex;
      }
      &:last-child {
        border-bottom: none;
      }
    }
  }

  // .dashboard-sidebar__container-popup

  &__container-popup {
    max-width: 300px;
    width: 100%;
    padding: 26px 40px;
    background-color: $white;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
    margin-right: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  // .dashboard-sidebar__btn

  &__btn {
    background: $white !important;
    color: $secondGray !important;
    font-size: 16px;
    padding: 8px 20px !important;
    margin-bottom: 16px;
    display: none !important;
    border-radius: 0px !important;
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1) !important;
    &:hover {
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1) !important;
    }
  }

  // .dashboard-sidebar__link

  &__link {
    padding: 16px 0;
    font-size: 18px;
    color: $sixthGray;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #b6b6b6;
    font-family: "AkzidenzGroteskPro-Ext", sans-serif;
    span {
      display: flex;
      align-items: center;
    }
    svg {
      margin-left: 12px;
    }
  }
  .router-link-active {
  }
}

@media (max-width: 1200px) {
  .arrow {
    width: 33px;
    height: 33px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    background: linear-gradient(180deg, #1816ae 0%, #050671 100%);
    box-shadow: 0px 2px 0px rgba(255, 255, 255, 0.5),
      0px 10px 10px rgba(37, 36, 157, 0.5),
      inset 0px 2px 4px rgba(255, 255, 255, 0.5);
    border-radius: 50px;
    svg {
      margin-left: 0;
      transition: 0.4s ease;
    }
  }
  .dashboard-sidebar {
    position: relative;
    height: 65px;
    margin-right: 0;
    margin-bottom: 16px;
    // .dashboard-sidebar__btn

    &__btn {
      display: flex !important;
      max-width: 390px;
      width: 100%;
      background-color: $white;
      box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
      border-radius: 0px;
      margin-bottom: 0;
      font-size: 18px;

      .v-btn__name {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        svg {
        }
      }
    }

    // .dashboard-sidebar__container

    &__container {
      position: absolute;
      width: 100%;
      height: 65px;
      overflow: hidden;
      top: 0;
      left: 0;
      padding: 10px 20px;
      z-index: 1;

      .dashboard-sidebar__link {
        span {
        }
        &:first-child {
          padding-top: 6px;
        }
        &:last-child {
        }
      }
    }

    // .dashboard-sidebar__link

    &__link {
      // border-bottom: none;
    }

    // .dashboard-sidebar__container-popup

    &__container-popup {
    }
  }
}

@media (max-width: 575px) {
  .dashboard-sidebar {
    max-width: initial;
  }
}

.dashboard-sidebar__link--active {
  color: #282735;
  font-family: "AkzidenzGroteskPro-MdEx", sans-serif;
  justify-content: space-between;
}

.dashboard-sidebar__link--active1 {
  color: #282735;
  font-family: "AkzidenzGroteskPro-MdEx", sans-serif;
  justify-content: space-between;
}

.dashboard-sidebar--active {
  .arrow {
    svg {
      transform: rotate(180deg);
    }
  }
}
</style>
