import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";
import gsap from "gsap";
import { getCookie } from "@/helpers/helpers.js";

const routes = [
  {
    path: "/",
    name: "HomeView",
    meta: {
      layout: "default",
      title: "Рестарт Онлайн",
      auth: false,
    },
    component: () =>
      import(/* webpackChunkName: "HomeView" */ "../views/HomeView.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      layout: "dashboard",
      title: "Рестарт Онлайн - Дэшборд",
      auth: true,
    },
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/DashboardView.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    meta: {
      layout: "auth",
      title: "Рестарт Онлайн - Вход",
      auth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "auth" */ "../views/AuthView.vue"),
  },
  {
    path: "/whatsapp_auth",
    name: "whatsapp_auth",
    meta: {
      layout: "auth",
      title: "Рестарт Онлайн - Вацап аутентификация",
      auth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (whatsapp_auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "whatsapp_auth" */ "../views/WhatsappAuthView.vue"
      ),
  },
  {
    path: "/messenger_auth",
    name: "messenger_auth",
    meta: {
      layout: "auth",
      title: "Рестарт Онлайн - Мессенджер аутентификация",
      auth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (messenger_auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "MessengerAuth" */ "../views/MessengerAuth.vue"
      ),
  },
  {
    path: "/telegram_auth",
    name: "telegram_auth",
    meta: {
      layout: "auth",
      title: "Рестарт Онлайн - Телеграм аутентификация",
      auth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (telegram_auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "telegram_auth" */ "../views/TelegramAuthView.vue"
      ),
  },
  {
    path: "/current_loan",
    name: "current_loan",
    redirect: "/documentation",
    meta: {
      layout: "dashboard",
      title: "Рестарт Онлайн - Текущий займ",
      auth: true,
      name: "Текущий займ",
    },
    // route level code-splitting
    // this generates a separate chunk (current_loan.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "current_loan" */ "../views/CurrentLoanView.vue"
      ),
  },
  {
    path: "/bids",
    name: "bids",
    redirect: "/documentation",
    meta: {
      layout: "dashboard",
      title: "Рестарт Онлайн - Заявки",
      auth: true,
      name: "Заявки",
    },
    // route level code-splitting
    // this generates a separate chunk (bids.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "bids" */ "../views/BidsView.vue"),
  },
  {
    path: "/loan_history",
    name: "loan_history",
    redirect: "/documentation",
    meta: {
      layout: "dashboard",
      title: "Рестарт Онлайн - История займов",
      auth: true,
      name: "История займов",
    },
    // route level code-splitting
    // this generates a separate chunk (loan_history.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "loan_history" */ "../views/LoanHistoryView.vue"
      ),
  },
  {
    path: "/cards_and_accounts",
    name: "cards_and_accounts",
    redirect: "/documentation",
    meta: {
      layout: "dashboard",
      title: "Рестарт Онлайн - Карты и счета",
      auth: true,
      name: "Карты и счета",
    },
    // route level code-splitting
    // this generates a separate chunk (cards_and_accounts.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "cards_and_accounts" */ "../views/CardsAndAccountsView.vue"
      ),
  },
  {
    path: "/profile",
    name: "profile",
    redirect: "/documentation",
    meta: {
      layout: "dashboard",
      title: "Рестарт Онлайн - Профиль",
      auth: true,
      name: "Профиль",
    },
    // route level code-splitting
    // this generates a separate chunk (profile.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/ProfileView.vue"),
  },
  {
    path: "/documentation",
    name: "documentation",
    meta: {
      layout: "dashboard",
      title: "Рестарт Онлайн - Документы",
      auth: true,
      name: "Документы",
    },
    // route level code-splitting
    // this generates a separate chunk (documentation.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "documentation" */ "../views/DocumentationView.vue"
      ),
  },
  {
    path: "/photos",
    name: "photos",
    redirect: "/documentation",
    meta: {
      layout: "dashboard",
      title: "Рестарт Онлайн - Фотографии",
      auth: true,
      name: "Фотографии",
    },
    // route level code-splitting
    // this generates a separate chunk (photos.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "photos" */ "../views/PhotosView.vue"),
  },
  {
    path: "/registration",
    name: "registration",
    redirect: "/",
    meta: {
      layout: "default",
      title: "Рестарт Онлайн - Заявка",
      auth: false,
      name: "Подача заявки",
    },
    // redirect: "/",
    // route level code-splitting
    // this generates a separate chunk (registration.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "registration" */ "../views/RegistrationView.vue"
      ),
  },
  {
    path: "/registration_success",
    name: "registration_success",
    redirect: "/",
    meta: {
      layout: "default",
      title: "Рестарт Онлайн - Регистрация успех",
      auth: false,
    },
    // redirect: "/",
    // route level code-splitting
    // this generates a separate chunk (registration_success.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "registration_success" */ "../views/SuccessRegistrationView.vue"
      ),
  },
  {
    path: "/signup",
    name: "signup",
    redirect: "/",
    meta: {
      layout: "default",
      title: "Рестарт Онлайн - Регистрация",
      auth: false,
    },
    // route level code-splitting
    // this generates a separate chunk (signup.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/SignupView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const preloader = document.querySelector(".v-preloader");
  const signup_zaglushka = true;
  gsap
    .to(preloader, 0.4, { opacity: 1 })
    .then(async () => {
      const token = getCookie("token");
      // const token = document.cookie.

      const requireAuth = to.matched.some((record) => record.meta.auth);
      document.title = to?.meta?.title || "Не определено";
      // await store.dispatch("SET_SAVED_TOKENS");
      if (requireAuth && token == undefined) {
        next("/auth");
        return;
      } else {
        if (requireAuth) {
          let res = await store.dispatch("GET_PHOTOS_COMPLUSION");
          if (requireAuth && res && to.href !== "/photos") {
            next("/photos");
          } else next();
        } else {
          next();
        }
      }
    })
    .then(() => {
      setTimeout(() => {
        gsap.to(preloader, 0.4, { opacity: 0 });
      }, 300);
    });
});

// router.beforeResolve(async (to, from) => {
//   console.log(to, from);
// });

export default router;
