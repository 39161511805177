<template>
  <article
    class="tariff__item"
    :class="{
      'tariff__item--active': data.selected == true,
      'first-tariff': idx == 0,
    }"
    @click="$emit('selectedTariff', data)"
    :style="{ backgroundColor: data.head_color }"
  >
    <div class="new_year_bg"></div>
    <div class="tariff__item-head">
      <h3 class="tariff__item-title">
        <!-- <span>0{{ data.id }}</span> -->
        <strong>
          {{ data.name }}
        </strong>
      </h3>
      <p class="tariff__item-condition">
        <span>
          от
          <strong>{{
            data?.min_amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }}</strong>
          ₽
        </span>
        <span>
          до
          <strong>{{
            data?.max_amount?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
          }}</strong>
          ₽
        </span>
      </p>
    </div>
    <v-divider
      class="tariff__item-divider"
      background="rgba(255, 255, 255, 1)"
    ></v-divider>
    <div class="tariff__item-body">
      <strong class="tariff__item-percent">
        {{ data?.description }} не более
        {{ data?.percent?.toString().replace(/[.]/gi, ",") + "%" }} в день
      </strong>
      <p class="tariff__item-info"></p>
    </div>
  </article>
</template>
<script>
export default {
  name: "v-tariff-item",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    idx: {
      type: Number,
      default() {
        return 0;
      },
    },
  },
  mounted() {},
};
</script>
<style lang="scss"></style>
