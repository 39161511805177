<template>
  <label :for="attrs.id" class="requisites__label">
    <div class="requisites__container">
      <div class="requisites__wrapper">
        <input
          type="radio"
          :name="attrs.name"
          :id="attrs.id"
          :value="value"
          @change="
            $emit('update:modelValue', $event.target.value), updateAccount(data)
          "
          :checked="data.default == 1"
          class="requisites__radio"
        />
        {{ data.type == "card" ? "Карта" : `Счет № ${attrs.index + 1}` }}
        {{ data.name }}
      </div>
      <slot name="append"> </slot>
    </div>
  </label>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name: "v-requisites-radio",
  props: {
    modelValue: { type: String, default: null },
    value: { type: String, default: null },
    attrs: { type: Object, default: null },
    data: { type: Object, default: null },
  },
  methods: {
    ...mapActions(["CHANGE_ACTIVE_REQUISITE"]),
    updateAccount(account) {
      this.CHANGE_ACTIVE_REQUISITE(account);
    },
  },
};
</script>
<style lang="scss">
.requisites {
  // .requisites__label

  &__label {
    width: 100%;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    &:last-child {
    }
  }

  // .requisites__container

  &__container {
    height: 100%;
  }

  // .requisites__wrapper

  &__wrapper {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-family: "AkzidenzGroteskPro-Ext", sans-serif;
  }

  // .requisites__radio

  &__radio {
    position: relative;
    appearance: none;
    margin-right: 16px;
    margin-left: 0;
    &::before {
      content: "";
      background-image: url("~@/assets/img/card_unchecked_icon.svg");
      background-size: 36px 36px;
      transition: all 0.3s;
      display: flex;
      cursor: pointer;
      width: 36px;
      height: 36px;
      overflow: hidden;
    }
    &:checked {
      &::before {
        background-image: url("~@/assets/img/card_checked_icon.svg");
      }
    }
  }
}
@media (max-width: 1600px) {
  .requisites {
    // .requisites__label

    &__label {
    }

    // .requisites__container

    &__container {
    }

    // .requisites__wrapper

    &__wrapper {
      font-size: 16px;
    }

    // .requisites__radio

    &__radio {
    }
  }
}
@media (max-width: 768px) {
  .requisites {
    // .requisites__label

    &__label {
    }

    // .requisites__container

    &__container {
    }

    // .requisites__wrapper

    &__wrapper {
    }

    // .requisites__radio

    &__radio {
    }
  }
}
</style>
