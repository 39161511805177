// import { string } from "../../../helpers/converters";

export default {
  PHOTOS(state) {
    return state.photos;
  },
  BIDS(state) {
    return state.bids;
  },
  PROFILE(state) {
    return state.profile;
  },
  LOANS_HISTORY(state) {
    return state.loans;
  },
  BILLS(state) {
    return state.bills;
  },
  DOCUMENTS(state) {
    return state.documents;
  },
  CURR_LOAN(state) {
    return state.curr_loan;
  },
  CARDS(state) {
    return state.cards;
  },
  BALANCE(state) {
    return state.balance;
  },
  PHOTOS_COMPLUSION(state) {
    return state.photos_complusion;
  },
  ACTIVE_LOANS(state) {
    return state.active_loans;
  },
  LK_DOCUMENTS(state) {
    return state.lk_documents;
  },
};
