import axios from "axios";
import apiRequest from "../../../middlewares/api-request";
import { toServerPhone } from "../../../helpers/converters";
import { getCookie, setCookie } from "../../../helpers/helpers";
import router from "@/router";

export default {
  async LOGOUT() {
    setCookie("token", "", 0);
    router.push("/auth");
  },
  async BIND_MESSENGER({ commit, state }, { messenger }) {
    return await apiRequest.function(
      "POST",
      `/lk/messengers/add`,
      [],
      undefined,
      { type: messenger },
      { Authorization: `${getCookie("token")}` }
    );
  },
  async CHECK_AUTH_CODE({ commit, state }, data) {
    return axios
      .post(`${state.apiURL}/sms/check`, data)
      .then((response) => {
        document.cookie = `phone=${toServerPhone(data.phone)}`;
        console.log(response);
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err.response;
      });
  },
  async GET_PHOTOS() {
    return await apiRequest.function(
      "POST",
      "/lk/photos/get",
      ["SET_PHOTOS"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_BIDS() {
    return await apiRequest.function(
      "POST",
      "/lk/loans",
      ["SET_BIDS"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_PROFILE() {
    return await apiRequest.function(
      "POST",
      "/lk/profile",
      ["SET_PROFILE"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_CURR_LOAN() {
    return await apiRequest.function(
      "POST",
      "/lk/currLoan",
      ["SET_CURR_LOAN"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_LOAN_HISTORY() {
    return await apiRequest.function(
      "POST",
      "/lk/loans",
      ["SET_LOAN_HISTORY"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_LOAN_OPERATIONS({ state }) {
    return await apiRequest.function(
      "POST",
      "/lk/loan/operations",
      ["SET_LOAN_OPERATIONS"],
      undefined,
      { orderId: 51803 },
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_ACTIVE_LOANS() {
    return await apiRequest.function(
      "POST",
      "/lk/loans/active",
      ["SET_ACTIVE_LOANS"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_REQUISITES() {
    return await apiRequest.function(
      "POST",
      "/lk/requisites/get",
      ["SET_REQUISITES"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async CHANGE_ACTIVE_REQUISITE({}, id) {
    return await apiRequest.function(
      "POST",
      "/lk/requisites/change",
      [],
      undefined,
      { requisite_id: id },
      { Authorization: `${getCookie("token")}` }
    );
  },
  async GET_LK_DOCUMENTS() {
    return await apiRequest.function(
      "POST",
      "/lk/documents",
      ["SET_LK_DOCUMENTS"],
      undefined,
      undefined,
      { Authorization: `${getCookie("token")}` }
    );
  },
  async APPEND_BILL({}, data) {
    const { rs, akb, bik, FIO, ks, bill_inn } = data,
      payload = {
        number: rs.value,
        name: akb.value,
        bik: bik.value,
        holder: FIO.value,
        inn_holder: bill_inn.value,
        correspondent_acc: ks.value,
        default: "0",
      };
    return await apiRequest.function(
      "POST",
      "/lk/requisites/account/add",
      [],
      undefined,
      payload,
      { Authorization: `${getCookie("token")}` }
    );
  },
};
