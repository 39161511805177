export default {
  SET_PHOTOS: (state, res) => {
    res = res;
    // res.map((item) => (item.status = 3));
    res.map((item) => {
      if (item.status == 3) {
        item.errorBag = [];
        item.errorBag.unshift({ name: "Не принят" });
      }
    });
    state.photos = res;
  },
  SET_BIDS: (state, res) => {
    state.bids = res.filter(
      (item) =>
        item?.type == "Заявка" &&
        item?.is_archived !== 1 &&
        item?.unreability !== 1 &&
        item?.status?.toLowerCase() !== "черновик" &&
        (item?.status.toLowerCase() == "новая" ||
          item?.status.toLowerCase() == "отказ" ||
          item?.status.toLowerCase() == "выдан" ||
          item?.status.toLowerCase() == "в обработке")
    );

    // state.bids[4].status = 'принята'
    // state.bids[5].status = 'А.подготовлена'
    // state.bids[6].status = 'У миддла'
    // state.bids[7].status = 'Р.подтверждена'
    // state.bids[8].status = 'Р.нецелесообразно'
  },
  SET_LOAN_HISTORY: (state, res) => {
    // state.loans = res;
    state.loans = res.filter(
      (item) =>
        item.type == "Микрозайм" &&
        (item?.status.toLowerCase() == "новая" ||
          item?.status.toLowerCase() == "отказ" ||
          item?.status.toLowerCase() == "выдан" ||
          item?.status.toLowerCase() == "в обработке")
    );
  },
  SET_PROFILE: (state, res) => {
    state.profile = res;
  },
  SET_CURR_LOAN: (state, res) => {
    state.curr_loan = [res.data];

    const abjArr = Object.entries(res.data.payment_schedule);
    const arr = [];

    abjArr.forEach(([key, value]) => {
      if (key !== "result") value.date = key;
      if (value?.date !== undefined) arr.push(value);
    });

    const overdueArr = [];
    const futureArr = [];

    // arr[0].date = "02.08.2022";

    arr.map((item) => {
      const date = new Date(item.date.replace(/[.]/g, "-"));
      const curr_date = new Date();
      item.pay_sum = item.pay_sum
        .toFixed(2)
        .toString()
        .replace(/[.]/g, ",")
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");

      // if (curr_date > date) {
      //   overdueArr.push(item);
      // }
      if (curr_date > date) {
        futureArr.push(item);
      }
    });

    const total = arr
      .map((item) => {
        return Number(
          item.pay_sum.toString().slice(0, -2).replace(/[ ,]/g, "")
        );
      })
      .reduce((a, b) => a + b);

    state.curr_loan[0].overdue_payments = overdueArr;
    state.curr_loan[0].payment_schedule = arr;
    state.curr_loan[0].future_payments = futureArr;
    state.curr_loan[0].total = total;
  },
  SET_USER_BALANCE: (state, res) => {
    state.balance = res.balance;
  },
  SET_LOAN_OPERATIONS: (state, res) => {},
  SET_ACTIVE_LOANS: (state, res) => {
    state.active_loans = res;
  },
  SET_REQUISITES: (state, res) => {
    state.bills = res?.accounts || [];
    // state.bills.push(state.bills[0]);
    // state.bills.push(state.bills[1]);
    // state.bills.push(state.bills[0]);
    // state.bills.push(state.bills[1]);
    state.cards = res?.cards || [];
  },
  SET_LK_DOCUMENTS: (state, res) => {
    const filtered = new Set(
      res.map((item) => {
        return (item.order_id = item.order_id.toString());
      })
    );
    const filtered2 = [];
    for (const el of filtered) {
      filtered2.push(new Array(res.filter((item) => item.order_id == el)));
    }
    state.lk_documents = filtered2;
  },
};
