<template>
  <div class="v-tooltip">
    <div
      class="v-tooltip__activator"
      :style="{ 'background-color': $attrs.color }"
    >
      <slot name="tooltip-activator"></slot>
    </div>
    <div
      class="v-tooltip__content"
      :style="{ width: $attrs.width || 200 + 'px' }"
    >
      <slot name="tooltip-content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "v-tooltip",
  props: {
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.v-tooltip {
  cursor: pointer;
  position: relative;
  font-weight: 400;
  // .v-tooltip__activator
  &__activator {
    min-width: 18px;
    min-height: 18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // .v-tooltip__content

  &__content {
    position: absolute;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 5px 5px 5px 0px;
    padding: 10px;
    color: $secondGray;
    font-size: 11px;
    line-height: 120%;
    font-family: "Lato", sans-serif;
    bottom: 175%;
    left: 50%;
    width: 200px;
    white-space: normal;
    background-color: #fff;
    opacity: 0;
    pointer-events: none;
    font-weight: inherit;
    transition: all 0.3s;
    text-align: left;
    &::before {
      content: "";
      position: absolute;
      top: 100%;
      left: 0;
      width: 0;
      height: 0;
      border-top: 10px solid $white;
      border-right: 10px solid transparent;
    }
  }

  &:hover {
    .v-tooltip__content {
      opacity: 1;
      pointer-events: initial;
    }
  }
}

@media (max-width: 1768px) {
  .v-tooltip {
    // .v-tooltip__content

    &__content {
      border-radius: 5px 5px 0px 5px;
      left: initial;
      right: 50%;
      &::before {
        content: "";
        position: absolute;
        top: 100%;
        left: initial;
        right: 0;
        transform: rotate(90deg);
        width: 0;
        height: 0;
        border-top: 10px solid $white;
        border-right: 10px solid transparent;
      }
    }
  }
}
</style>
