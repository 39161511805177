<template>
  <section class="auth-layout">
    <v-header class="standart">
      <div class="header__wrapper">
        <router-link to="/registration" class="header__link header__btn cta-btn"
          >Зарегистрироваться</router-link
        >
      </div>
    </v-header>

    <router-view />

    <v-footer></v-footer>
  </section>
</template>
<script>
export default {
  name: "auth-layout",
};
</script>
<style lang="scss">
.auth-layout {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}
</style>
