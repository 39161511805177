const toDate = function (val) {
  return val
    ?.toString()
    ?.replace(/[.]/g, "")
    ?.slice(0, 10)
    ?.replace(/(\d{2})(\d{2})(\d{4})/, "$3-$2-$1");
};

const toServerDate = function (val) {
  return val
    ?.toString()
    ?.replace(/[.-]/g, "")
    ?.slice(0, 8)
    ?.replace(/(\d{2})(\d{2})(\d{4})/, "$3-$2-$1");
};

const dateReverse = function (val, separator) {
  if (val?.length > 10) {
    return val
      ?.replace(/[-]/g, "")
      ?.slice(0, 8)
      ?.replace(/(\d{4})(\d{2})(\d{2})/, `$3${separator}$2${separator}$1 `);
  }
  if (val?.split("-")?.[0].length > 2) {
    return val?.replace(
      /(\d{4})-(\d{2})-(\d{2})/,
      `$3${separator}$2${separator}$1`
    );
  }
  if (val?.split("-")?.[2].length > 2) {
    return val?.replace(
      /(\d{2})-(\d{2})-(\d{4})/,
      `$3${separator}$2${separator}$1`
    );
  } else {
    return val?.replace(
      /(\d{4})-(\d{2})-(\d{2})/,
      `$1${separator}$2${separator}$3`
    );
  }
};

const toServerPhone = function (val) {
  return val?.replace(/[+() -]/gi, "");
};

const toRuble = function (val) {
  return val
    ?.toFixed(2)
    ?.toString()
    ?.replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    ?.replace(/[.]/g, ",");
};

export { toDate, dateReverse, toServerPhone, toRuble, toServerDate };
