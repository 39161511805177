<template>
  <svg
    :class="$attrs.class || 'w-6 h-6'"
    :width="$attrs.width || '16px'"
    :height="$attrs.height || '16px'"
    class="v-icon"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    v-html="path"
  ></svg>
</template>
<script>
import icons from '@/components/UI/icons'

export default {
  props: {
    name: {
      type: String,
      default () {
        return ''
      }
    }
  },
  name: 'v-icon',
  data () {
    return {
      path: icons[this.name]
    }
  }
}
</script>
<style lang="scss" scoped>
</style>
